import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { API, STRAPI_API } from "../../constant";
import { setToken, setExpire } from "../../helpers";
import { getToken, removeToken, getExpire } from "../../helpers";

const SignIn = (props) => {
  const { isDesktopView } = useScreenSize();
  const { setUser, user, sub } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setEmail] = useState(false);
  const [error, setError] = useState("");

    if (!isLoading) {
      if (sub) {
          props.history.push("/admin/benutzerkonto");
      } else if (user && user.confirmed === true) {
          props.history.push("/admin/aktionen");
      }
    }


  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const value = {
        identifier: values.email,
        password: values.password,
      };
      const response = await fetch(`${API}/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      const data = await response.json();
      if ((data || {}).error) {
        throw (data || {}).error;
      } else {
        // set the token
        setToken(data.jwt);

        const oneDayFromToday = new Date();
        oneDayFromToday.setDate(oneDayFromToday.getDate() + 1);
        setExpire(oneDayFromToday);

        // set the user
        setUser(data.user);
        message.success(`Willkommen ${data.user.Name || data.user.username}!`);

        const selectedEmail = document.getElementById("emailSignId").value;

        if (data.user.confirmed === true) {
            props.history.push("/admin/aktionen");
        }
       setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setError("Email und/ oder Passwort nicht gefunden!");
    } finally {
    }
  };

  return (
    <Fragment>
      <Row align="middle">
        <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
          <Card title="Anmelden">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError("")}
              />
            ) : null}
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input id="emailSignId" placeholder="Email" />
              </Form.Item>

              <Form.Item
                label="Passwort"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password placeholder="Passwort" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login_submit_btn"
                >
                  Anmelden {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>
            </Form>
            <Typography.Paragraph className="form_help_text">
              Noch kein Nutzerkonto? <Link to="/signup">Zur Registrierung</Link>
            </Typography.Paragraph>
            <Typography.Paragraph className="form_help_text">
              Passwort vergessen? <Link to="/password-reset">Passwort zurücksetzen</Link>
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withRouter(SignIn);
