import React, { Component, useContext } from 'react';
import { Link, withRouter } from "react-router-dom";
import Header from './Header/index';
import TopPicks from './Picks';
import { passwordStrength } from 'check-password-strength'
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import Profile from "../components/Profile/Profile";
import { getToken } from "../helpers";
import { API, STRAPI_API } from "../constant";
import {
  Alert,Popconfirm,
  Avatar, Badge, Space, Select,
  Button, Card, Col, Form, Input, message, Row, Spin,
  Typography
} from "antd";
import './Styles/new-york.css';
import { AuthContext } from "../context/AuthContext";

const { Option } = Select;

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

const confirm = (e,text) => {
  message.success(text);
};
const cancel = (e, text) => {
  message.error(text);
};

 const handleSubChange = ({ planStatus, subId }) => {
    if (planStatus === "AKTIV") {
        cancelSubscription({ subId: subId });
        confirm("", "Plan storniert");
    } else {
        reactivateSubscription({ subId: subId });
        confirm("", "Plan reaktiviert");
    }
}

 const reactivateSubscription = ({ subId }) => {
      const cancelSubUrl = `${STRAPI_API}/reactivateSubscription/${subId}`;

    try {
        fetch(cancelSubUrl, {
          method: 'get',
          mode: 'cors',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`,
          }),
        }).then(response => {
            if (response.ok && response.status !== 204) {
              response.json().then(json => {
                // reload page
                window.location.reload();
              });
            }
          })
        } catch (error) {
          console.error(error);
        }
  }

  const cancelSubscription = ({ subId }) => {
      const cancelSubUrl = `${STRAPI_API}/cancelSubscription/${subId}`;

    try {
        fetch(cancelSubUrl, {
          method: 'get',
          mode: 'cors',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`,
        }),
        }).then(response => {
            if (response.ok && response.status !== 204) {
              response.json().then(json => {
                // reload page
                window.location.reload();
              });
            }
          })
        } catch (error) {
          console.error(error);
        }
  }

 const PasswortChange = () => {
     const { user, sub, setSub } = useContext(AuthContext);
     return (
             <div style={{ textAlign: 'left'}}>
                <Form
                    layout="vertical"
                    initialValues={{

                    }}
                    onFinish={(values) => {
                        const oldPassword = values["password-old"];
                        const passwordConfirmation = values["password-confirmation"];
                        const newPassword = values["password-new"];
                        const pStrength = passwordStrength(newPassword).value;
                        if (pStrength === "Too weak") {
                            cancel("", "Neues Passwort zu schwach");
                        } else {
                                const value = {
                                        identifier: values.email,
                                        password: values.password,
                                      };
                                      fetch(`${API}/auth/change-password`, {
                                        method: "POST",
                                        headers: {
                                          "Content-Type": "application/json",
                                          'Authorization': `Bearer ${getToken()}`,
                                        },
                                        body: JSON.stringify(
                                        {
                                            currentPassword: oldPassword,
                                            password: newPassword,
                                            passwordConfirmation: passwordConfirmation,
                                        }),
                                      })
                                        .then(response => response.json())
                                        .then(data => { console.log(data);

                                        if (data.error) {
                                            cancel("", "Fehler beim Passwort ändern");
                                        } else {
                                            confirm("", "Passwort geändert");
                                        }
})
                                        .catch((error) => { console.error('Error:', error);
                                         cancel("", "Fehler beim Passwort ändern");
                                         });
                        }
//                                const response = fetch(`${API}/auth/local`, {
//                                    method: "POST",
//                                    headers: {
//                                        "Content-Type": "application/json",
//                                    },
//                                    body: JSON.stringify(data),
//                                });
                    }
                    }
                >
                <Row gutter={[16, 16]}>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                        label="Altes Passwort"
                        name="password-old"
                        rules={[
                            {
                                required: true,
                                message: 'Bitte geben Sie Ihr altes Passwort ein!',
                            },
                        ]}
                    >
                        <Input.Password style={{ width: 300 }} />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                        label="Neues Passwort"
                        name="password-new"
                        rules={[
                            {
                                required: true,
                                message: 'Bitte gebem Sie Ihr neues Passwort ein!',
                            },
                        ]}
                    >
                        <Input.Password style={{ width: 300 }} />
                    </Form.Item>
                  </Col>
                  <Col md={6} lg={6} sm={24} xs={24}>
                    <Form.Item
                        label="Neues Passwort Wiederholen"
                        name="password-confirmation"
                        rules={[
                            {
                                required: true,
                                message: 'Bitte gebem Sie Ihr neues Passwort ein!',
                            },
                        ]}
                    >
                        <Input.Password style={{ width: 300 }} />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Passwort ändern
                        </Button>
                    </Form.Item>
                  </Col>
                  </Row>
                </Form>
         </div>)
 }

 const PlanConsumer = () => {
  const { availableSubs, user, sub, setSub } = useContext(AuthContext);
  const selectedSubTitle = sub ? ((availableSubs || []).find((availableSub) => availableSub.stripePlanId === ((sub || {}).plan || {}).id) || {}).title : false;

const planStatus = sub ? (sub.status === "active" && !sub.cancel_at_period_end ? "AKTIV" : "STORNIERT") : false;
 return (
         <div style={{ textAlign: 'left'}}>
                 { !sub && <p>Noch haben Sie keinen Plan. Die möglichen Optionen können Sie unter <Link
                   to={{ pathname: `/admin/aktionen` }}
                   key={'plan_id'}
                   className="link"
                   style={{ color: '#eb008c'}}
                 >
                   Abonnements
                 </Link> einsehen.</p> }
                 {  sub && <>
                 <Badge status={ planStatus === "AKTIV" ? "success" : "warning" } count={planStatus}>
                       <div shape="square" size="large"> <h2 style={{ background: 'aliceblue',
                                                                          padding: '0.4em',
                                                                          marginTop: '0px' }}>{selectedSubTitle}</h2> </div>
                 </Badge>
                 <Row gutter={[16, 16]}>
                           <Col md={8} lg={8} sm={24} xs={24}>
                           {sub.cancel_at_period_end && <><span style={{ fontSize: 22, color: "#ff4d4f" }}>Plan storniert zum <b>{formatDate(new Date(sub.cancel_at * 1000))}</b></span>
                           <p>Andere möglichen Optionen können Sie unter <Link
                                              to={{ pathname: `/admin/aktionen` }}
                                              key={'plan_id'}
                                              className="link"
                                              style={{ color: '#eb008c'}}
                                            >
                                              Abonnements
                                            </Link> einsehen.</p>
                           </>}
                           { planStatus === "AKTIV" && <span style={{ fontSize: 22, color: "#52c41a" }}>Nächster Abrechnungsdatum:<b style={{paddingLeft: 6}}>{formatDate(new Date(sub.current_period_end * 1000 + 1000*60*60*24))}</b></span>}
                 </Col>
                           <Col md={8} lg={8} sm={24} xs={24}>

                        <Popconfirm
                                  title={ planStatus === "AKTIV" ? "Plan wirklich stornieren?" : "Plan wirklich wieder aktivieren?" }
                                  onConfirm={() => {handleSubChange({ planStatus, subId: sub.id }); }}
                                  onCancel={cancel}
                                  okText="Ja, ich bin mir sicher."
                                  cancelText="Nein"
                                >
                                  <Button style={{marginLeft: 20 }}
                                                             className="profile_save_btn"
                                                             type={ planStatus === "AKTIV" ? "danger" : "primary" }
                                                             size="large"
                                                           >
                                                               { planStatus === "AKTIV" ?  "Stornieren" : "Wieder Aktivieren" }

                                                           </Button>
                                </Popconfirm>


                           </Col></Row>

</> }
         </div>)
 }


class Benutzerkonto extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {

    const token = getToken();

    if (!token) {
         this.props.history.push(`/anmelden`);
         return (<></>);
    }
    else {
    }
    // /anmelden

    return (
      <>
<div className="city-section-wrapper static-padding"><br /><br /><br /><br />
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      Benutzerkonto
    </h2>

    <hr
      style={{
        backgroundColor: '#eb008c',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    { <Profile /> }
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
    </p>

  <br />
    <div className="card-wrapper">
    <h2
          style={{
            textAlign: 'left', marginBottom: 8
          }}
        >
          Passwort ändern
        </h2>
            <hr
              style={{
                backgroundColor: '#eb008c',
                width: '75px',
                height: '2px',
                border: 'none',
                marginTop: '0px',
                marginLeft: '0px',
                marginBottom: '10px'
              }}
            />
            <PasswortChange />
  </div>
  <br />
    <div className="card-wrapper">

  </div>
  </div>

    </>
    );
  }
}

export default withRouter(Benutzerkonto);
