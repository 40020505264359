import React, { Component } from 'react';
import { Rate, message } from 'antd';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { actions, Table, Pagination }  from 'react-data-components';
import 'react-data-components/css/table-twbs.css';

import Autocomplete from './Autocomplete';
import { Typography, Divider, Row, Col } from 'antd';
import DataTable from './Table/CustomDataTable';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';
import MedienCarousel from './MedienCarousel';
import { API_BASE } from '../constant';

const desc = ['absolut nicht empfehlendswert', 'nicht empfehlendswert', 'befriedigend', 'empfehlendswert', 'sehr empfehlendswert'];

const columns = [
  { title: '', prop: 'image', render: (image) => <div className="city-card-img city-card-img--table" style={{ backgroundImage: `url(${image})` }}></div> },
  { title: 'Veranstaltung', prop: 'label', render : (label) => <span className="label">{label}</span> },
  { title: 'Datum', prop: 'date', sortable: true, render: (date) => {
  var date = new Date(date);

  // Extracting day, month, and year components
  var day = date.getDate();
  var month = date.getMonth() + 1; // Adding 1 as months are 0-based
  var year = date.getFullYear() - 2000; // Assuming you want to represen

  var formattedDate = (day > 9 ? day : ('0' + day)) + '.' +  (month > 9 ? month : ('0' + month))  + '.' + year;
  return <span>{formattedDate}</span> }},
  { title: 'R-Punkte', prop: 'points', render : (points) => <span className="points">{points}</span> },
];

const columnsExtended = [ ...columns,
  { title: 'ICW Zertifizierung', prop: 'ICW', render: (ICW) => <span>{ICW ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times"></i>}</span> },
  { title: 'DGfw Zertifizierung', prop: 'DGfw', render: (ICW) => <span>{ICW ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times"></i>}</span>  },
];


class SelectableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  };

  deSelectRow = (row) => {
    this.setState({ selected: '' });
  };

  buildRowOptions = (row) => {
    return {
//      onMouseOver: () => this.selectRow(row),
//      onMouseOut: () => this.deSelectRow(row),
      className: this.state.selected === row.id ? 'active-row' : null,
    };
  };

  render() {
    return (
      <DataTable buildRowOptions={this.buildRowOptions} {...this.props} />
    );
  }
}

class Veranstaltung extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      selected: ''
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  }

//  buildRowOptions = (row) => { alert(row.name);
//   return {
//     onClick: this.selectRow.bind(this, row),
//     className: this.state.selected === row.id ? 'active' : null
//   }}

 formatDate = (date) => {
      var date = new Date(date);

      // Extracting day, month, and year components
      var day = date.getDate();
      var month = date.getMonth() + 1; // Adding 1 as months are 0-based
      var year = date.getFullYear() - 2000; // Assuming you want to represen

      var formattedDate = (day > 9 ? day : ('0' + day)) + '.' +  (month > 9 ? month : ('0' + month))  + '.' + year;
      return <span>{formattedDate}</span>
  }

  render() {

  const { veranstaltungenData, match } = this.props;
  const vId = match.params.id;

  const foundEntity = veranstaltungenData.find( v => v.id === +vId );

  const setValue = (iValue) => {
  const value = iValue === 0 ? 4 : iValue;
    message.success({
        content: `Ihre Bewertung (${desc[Math.round(value-1)]}) wurde gespeichert.`,
        className: 'custom-class',
        style: {
        },
      });
  }

    return foundEntity ? (
      <>
  <CitySection
  style={{ marginTop: -8 }}
      key={1}
      headline={<h2 style={{ textAlign: 'left', fontWeight: 400, marginBottom: 8 }}>{foundEntity.Name}</h2>}
      description={<>
      <div className={"leafContainer leafContainer--veranstaltung"}>
      { (((foundEntity.Logo || {}).data || {}).attributes) && <img className={"leafImage leafImage--veranstaltung"}  src={API_BASE + foundEntity.Logo.data.attributes.url}  />}
      <br/>
        <div className="row referent">
            <div className="col-md-6">

            <p className="leaf--p">
            <span className="leaf--title">Name</span>
            <span className="leaf--value">{foundEntity.Name}</span>
            </p>
            <p className="leaf--p">
            <span className="leaf--title">Kategorie</span>
            <span className="leaf--value">{foundEntity.Kategorie}</span>
            </p>
            <p className="leaf--p">
            <span className="leaf--title">Ort</span>
            <span className="leaf--value">{foundEntity.Stadt} {foundEntity.PLZ}</span>
            </p>
            {foundEntity.Aufgabengebiete && <p className="leaf--p">
            <span className="leaf--title">Aufgabengebiete</span>
            <span className="leaf--value">{foundEntity.Aufgabengebiete.join(", ") }</span>
            </p> }
            <p className="leaf--p">
            <span class="i-button i-button--content" onClick={() => { window.open('https://' + foundEntity.Homepage, '_blank');  }}>Webseite besuchen</span>
            </p>
            </div>
        </div>
        </div>

        { ((foundEntity.Medien || {}).data || []).length > 0 && <Col xs={24} sm={24}>

                        <MedienCarousel overwriteTitle={"Galerie"} pickedData={foundEntity.Medien.data.map( el => el.attributes)} />
                  </Col> }
                <p className="full-desc">{foundEntity.description}</p>{ foundEntity.Homepage && <p>
<br />
            <span className="leaf--v">Weitere Informationen:</span>
            <a target="_blank" href={"https://" + foundEntity.Homepage} className="row external__link">https://{foundEntity.Homepage}</a>
        </p>}

        <div style={{ padding: '20px', border: '1px solid #eb008c' }}>
          <Divider orientation="left">Alle Informationen auf einen Blick</Divider>
          <Row gutter={[24, 24]}>
            {foundEntity.Name && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Name:</strong> {foundEntity.Name}</Typography.Text>
                </Col>
              )}
              {foundEntity.Kategorie && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Kategorie:</strong> {foundEntity.Kategorie}</Typography.Text>
                </Col>
              )}
              {foundEntity.Stadt && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Stadt:</strong> {foundEntity.Stadt}</Typography.Text>
                </Col>
              )}
              {foundEntity.PLZ && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>PLZ:</strong> {foundEntity.PLZ}</Typography.Text>
                </Col>
              )}
              {foundEntity.Bundesland && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Bundesland:</strong> {foundEntity.Bundesland}</Typography.Text>
                </Col>
              )}
              {foundEntity.Fachgebiete && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Fachgebiete:</strong> {foundEntity.Fachgebiete}</Typography.Text>
                </Col>
              )}
              {foundEntity.Barrierefreiheit != null && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Barrierefreiheit:</strong> {foundEntity.Barrierefreiheit ? 'Ja' : 'Nein'}</Typography.Text>
                </Col>
              )}
              {foundEntity.Ausstattung && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Ausstattung:</strong> {foundEntity.Ausstattung}</Typography.Text>
                </Col>
              )}
              {foundEntity.Bettenanzahl && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Bettenanzahl:</strong> {foundEntity.Bettenanzahl}</Typography.Text>
                </Col>
              )}
              {foundEntity.BehandelteKrankheiten && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>BehandelteKrankheiten:</strong> {foundEntity.BehandelteKrankheiten}</Typography.Text>
                </Col>
              )}
              {foundEntity.Homepage && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Homepage:</strong> {foundEntity.Homepage}</Typography.Text>
                </Col>
              )}
              {foundEntity.Behandlungsmoeglichkeiten && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Behandlungsmoeglichkeiten:</strong> {foundEntity.Behandlungsmoeglichkeiten}</Typography.Text>
                </Col>
              )}
              {foundEntity.Spezialisierung && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Spezialisierung:</strong> {foundEntity.Spezialisierung}</Typography.Text>
                </Col>
              )}
              {foundEntity.Aufgabengebiete && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Aufgabengebiete:</strong> {foundEntity.Aufgabengebiete}</Typography.Text>
                </Col>
              )}
              {foundEntity.Spezialbehandlungen && (
                <Col xs={24} sm={24}>
                  <Typography.Text><strong>Spezialbehandlungen:</strong> {foundEntity.Spezialbehandlungen}</Typography.Text>
                </Col>
              )}
          </Row>
        </div>



        </>
        }
    />

    <br />
      </>
    ) : <h2>Referent nicht gefunden</h2>;
  }
}
//        <Media />
//        <Collections collectionsData={collectionsData} />
const CitySection = ({ style, headline, description, cardsData }) => (
  <div style={style} className="city-section-wrapper">
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      {headline}
    </h2>
    <hr
      style={{
        backgroundColor: '#eb008c',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
      {description}
    </p>
    <div className="travel-card-wrapper">

    </div>
  </div>
);

export default withRouter(Veranstaltung);