import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, message, Row, Spin } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";
import { getToken } from "../../helpers";
import styled, { createGlobalStyle } from 'styled-components';
import ReferentProfile from "../ReferentProfile";
import MeineDozenten from "../MeineDozenten";
import Anbieterwaltung from "../Anbieterverwaltung";
import Dozentenverwaltung from "../Dozentenverwaltung";
import Seminarverwaltung from "../Kursverwaltung";
import Exportverwaltung from "../Exportverwaltung";
import Auditorverwaltung from "../Auditorverwaltung";
import MeineKurse from "../MeineKurse";
import ProduktProfile from "../ProduktProfile";
import ProfilAnerkennungProfile from "../ProfilAnerkennungProfile";
import AuditbogenProfile from "../AuditberichtProfile";
import DozentAnerkennungProfile from "../DozentAnerkennungProfile";
import KursAnerkennungProfile from "../KursAnerkennungProfile";



// Global style for antd
const GlobalStyle = createGlobalStyle`
  @import '~antd/dist/antd.css';
`;

const InserierenLeaf = (props) => {
  const [loading, setLoading] = useState(false);
  const { user, isLoading, setUser } = useAuthContext();
  const [refId, setRefId] = useState('');

  useEffect(() => {
      // This block of code will run when the 'yourProp' prop changes
      // Compare previous and current props, and trigger a re-render if needed
//      if (props.referentenData.length !== prevProps.referentenData.length) {
        // Take any actions you need when the prop changes
        // For example, update local state or perform other operations
        setRefId('New Value');
//      }
    }, [props.referentenData]);

  const handleInserierenLeafUpdate = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/users/${user.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();

      message.success("Daten erfolgreich gespeichert!");
    } catch (error) {
      console.error(Error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }


  const isICW = (user || {}).Rolle === "ICW-Admin";

  const formByType = {
    "Dozentenverwaltung": <Dozentenverwaltung  isICW={isICW} history={props.history} type={props.type} id={props.id} anbieterData={props.veranstaltungenData.filter(v => v.type === "anbieter-profil")} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "dozent")} />,
    "Dozent" : <DozentAnerkennungProfile  isICW={isICW} history={props.history} type={props.type} id={props.id} herstellerData={props.veranstaltungenData.filter(v => v.type === "dozent")}  referentenData={props.veranstaltungenData.filter(v => v.type === "Referent")} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "Veranstaltung")} />,
    "Seminar" : <KursAnerkennungProfile  isICW={isICW} auditData={props.veranstaltungenData.filter(v => v.type === "audit-bogen")}  veranstaltungenData={props.veranstaltungenData} anbieterData={props.veranstaltungenData.filter(v => v.type === "anbieter-profil")} history={props.history} type={props.type} id={props.id} dozentenData={props.veranstaltungenData.filter(v => v.type === "dozent")} herstellerData={props.veranstaltungenData.filter(v => v.type === "seminar")}  referentenData={props.veranstaltungenData.filter(v => v.type === "Referent")} />,
    "Profil": <ProfilAnerkennungProfile  isICW={isICW} history={props.history} type={props.type} id={props.id} herstellerData={props.veranstaltungenData.filter(v => v.type === "anbieter-profil")}  referentenData={props.veranstaltungenData.filter(v => v.type === "Referent")} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "Veranstaltung")} />,
    "Dozenten" : <MeineDozenten  isICW={isICW} history={props.history} type={props.type} id={props.id} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "dozent")} />,
    "Auditbericht":  <AuditbogenProfile  isICW={isICW} history={props.history} type={props.type} id={props.id} herstellerData={props.veranstaltungenData.filter(v => v.type === "audit-bogen")} anbieterData={props.veranstaltungenData.filter(v => v.type === "anbieter-profil")} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "seminar")} />,
    "Seminare": <MeineKurse   dozentData={props.veranstaltungenData.filter(v => v.type === "dozent")} isICW={isICW} veranstaltungenData={props.veranstaltungenData}  anbieterData={props.veranstaltungenData.filter(v => v.type === "anbieter-profil")} auditData={props.veranstaltungenData.filter(v => v.type === "audit-bogen")} user={user} history={props.history} type={props.type} id={props.id} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "seminar")} />,
    "Auditorverwaltung": <Auditorverwaltung  isICW={isICW} auditData={props.veranstaltungenData.filter(v => v.type === "audit-bogen")} history={props.history} type={props.type} id={props.id} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "seminar")} auditorsData={props.veranstaltungenData.filter(v => v.type === "auditor")}  />,
    "Seminarverwaltung": <Seminarverwaltung  isICW={isICW} veranstaltungenData={props.veranstaltungenData} anbieterData={props.veranstaltungenData.filter(v => v.type === "anbieter-profil")}  history={props.history} type={props.type} id={props.id} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "seminar")} />,
    "Kursexport": <Exportverwaltung       isTuev={false} isICW={isICW} veranstaltungenData={props.veranstaltungenData} anbieterData={props.veranstaltungenData.filter(v => v.type === "anbieter-profil")}  history={props.history} type={props.type} id={props.id} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "seminar")} />,
    "Abrechnungsexport": <Exportverwaltung isTuev={true} isICW={isICW} veranstaltungenData={props.veranstaltungenData} anbieterData={props.veranstaltungenData.filter(v => v.type === "anbieter-profil")}  history={props.history} type={props.type} id={props.id} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "seminar")} />,
    "Anbieterverwaltung": <Anbieterwaltung history={props.history} isICW={isICW} type={props.type} id={props.id} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "anbieter-profil")}   />,
  }

  const individualFormByType = {
    "Dozenten" : <DozentAnerkennungProfile history={props.history} type={props.type} id={props.id} herstellerData={props.veranstaltungenData.filter(v => v.type === "dozent")}  referentenData={props.veranstaltungenData.filter(v => v.type === "Referent")} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "Veranstaltung")} />,
    "Seminare" : <KursAnerkennungProfile auditData={props.veranstaltungenData.filter(v => v.type === "audit-bogen")} anbieterData={props.veranstaltungenData.filter(v => v.type === "anbieter-profil")} history={props.history} dozentenData={props.veranstaltungenData.filter(v => v.type === "dozent")} type={props.type} id={props.id} herstellerData={props.veranstaltungenData.filter(v => v.type === "seminar")}  referentenData={props.veranstaltungenData.filter(v => v.type === "Referent")} veranstaltungenData={props.veranstaltungenData.filter(v => v.type === "Veranstaltung")} />,

  }
  const { history } = props;

const match = history.location.pathname.match(/\/Dozenten\/(\d+)/) ||  history.location.pathname.match(/\/dozenten\/(\d+)/) ||  history.location.pathname.match(/\/Seminare\/(\d+)/);

  return (
  <>
    <Card className="InserierenLeaf_page_card">
      { (props.type !== "Profil" && match) ? individualFormByType[props.type] : formByType[props.type] }
    </Card>
    </>
  );
};

export default InserierenLeaf;
