import React, { useEffect, useState,Component, useContext } from "react";
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
import { getToken, removeExpire, removeToken } from "../../helpers";
import { API } from "../../constant";
import Autocomplete from '../Autocomplete';
import Background from './Background';
import Logo from '../Images/ICW_Logo_mobile.png';
import '../Styles/Header.css';
import { useAuthContext } from "../../context/AuthContext";
import { Avatar } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown,Divider, message, Space, Tooltip } from 'antd';
import { elastic as Menu } from 'react-burger-menu'
import Header from './HeaderChild';



function scrollToTargetAdjusted(){
    var element = document.getElementById('go-filter');
    var headerOffset = document.getElementsByClassName("first-line")[0].clientHeight + document.getElementsByClassName("second-line")[0].clientHeight + 14;
//    ("first-line").clientHeight + document.getElementById("second-line").clientHeight;

    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
}

const HeaderWrapper = (props) => {
    const { user } = useAuthContext();

    const fetchData = async () => {
      try {
        const userId = (user || {}).id;
        if ((user || {}).error && props.history.location.pathname !== '/datenschutz' && props.history.location.pathname.indexOf('password-new') === -1) {
            removeToken();
            removeExpire();
            if (props.history.location.pathname !== '/datenschutz') {
                return props.history.push(`/anmelden`);
            }
        }

        if (typeof userId === 'undefined') return null;

        const userIsAuditor = (user || {}).Rolle === "Auditor" ||  (user || {}).Rolle === "ICW-Admin";
//        const response = await fetch('/dummyVeranstaltungen.json');
        const responseVeranstaltungen = await fetch(`${API}/anbieter-profils?populate=*`,
             {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getToken()}`,
                }
              }
          );
        const responseReferenten = await fetch(`${API}/dozents?populate=*`,
             {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getToken()}`,
                }
              }
          );
        const responseHersteller = await fetch(`${API}/kurss?populate=*`,
             {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getToken()}`,
                }
              }
          );
        const responseAuditor = await fetch(`${API}/auditors`,
             {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getToken()}`,
                }
              }
          );


//        const responseReferenten = await fetch(`${API}/referents?populate=Bild,Medien,veranstaltungs,veranstaltungs.Bild`);
//        const responseHersteller = await fetch(`${API}/herstellers?populate=Logo,Medien,veranstaltungs,veranstaltungs.Bild,veranstaltungs,Produkt,Produkt.Produktgroesse,Produkt.Medien,Produkt.Produktbild,Produkt.Datenblaetter`);

        const responseVeranstaltungenData = await responseVeranstaltungen.json();
        const responseReferentenData = await responseReferenten.json();
        const responseHerstellerData = await responseHersteller.json();
        const responseAuditorData = await responseAuditor.json();

        let cleanedAuditBogen = [];
        if (userIsAuditor) {
            const responseAuditBogen = await fetch(`${API}/audit-bogens`,
                 {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${getToken()}`,
                    }
                  }
              );

            const responseAuditBogenData = await responseAuditBogen.json();
            cleanedAuditBogen = responseAuditBogenData.data.map((d) => {
                return { ...d.attributes, id: d.id, type: 'audit-bogen' };
            });
        }


        const cleanedVeranstaltungen = responseVeranstaltungenData.map((d) => {
            return { ...d, id: d.id, type: 'anbieter-profil' };
        });

        const cleanedReferenten = responseReferentenData.map((d) => {
            return { ...d, id: d.id, type: 'dozent' };
        });

        const cleanedHersteller = responseHerstellerData.map((d) => {
            return { ...d, id: d.id, type: 'seminar' };
        });

        const cleanedAuditor = responseAuditorData.filter((d) => d.Rolle === "Auditor").map((d) => {
            return { ...d, id: d.id, type: 'auditor' };
        });


        const cleanedProdukt = []

        const data = [...cleanedVeranstaltungen, ...cleanedReferenten, ...cleanedProdukt, ...cleanedHersteller, ...cleanedAuditor, ...cleanedAuditBogen];// await response.json();
        props.setVeranstaltungenData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

      useEffect(() => {
          if (user === null) {
            removeToken();
            if (props.history.location.pathname !== '/datenschutz' && props.history.location.pathname.indexOf('password-new') === -1) props.history.push("/anmelden", { replace: true });
          } else {
            fetchData();
          }
      }, [user]);

      useEffect(() => {
//          if (props.history.location.pathname === '/admin/aktionen') {
                fetchData();
//          }
      }, [props.history.location.pathname]);

    return (
            <Header veranstaltungenData={props.veranstaltungenData} user={user} />
    );
}

const SioAvatar = (props) => {
    const { user } = useAuthContext();
    return (
        <Tooltip placement="bottom" title={ user ? 'Benutzerkonto' : 'Anmelden'}>
            <Avatar style={{ marginTop: -8, backgroundColor: (user ? '#52c41a' : '#f8f8f8'), color: (user ? '#fff' : '#eb008c') }} icon={<UserOutlined />} />
        </Tooltip>
    );
}

export class HeaderNav extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
      adminActive: false
    }
  }

  componentDidMount() {
    // Access history after the component has mounted
    const { pathname } = this.props;
      this.setState({
        adminActive: true //pathname.indexOf("/admin") > -1
      });
  }

  componentDidUpdate(prevProps) {
      const { pathname } = this.props;
      if (prevProps.pathname !== pathname) {
        this.setState({
          adminActive: true //pathname.indexOf("/admin") > -1
        });
      }
  }

  static defaultProps = {
    navigationData: [
    ],
    editorNavigationData: [
  {
          id: 3,
          name: 'Aktionen',
          url: 'admin/aktionen'
        },
  {
          id: 4,
          name: 'Mein Profil',
          url: 'admin/aktionen/profil'
        },
      {
        id: 5,
        name: 'Meine Dozenten',
         url: 'admin/aktionen/dozenten',
      },
      {
        id: 5,
        name: 'Meine Seminare',
         url: 'admin/aktionen/Seminare',
      },
      {
        id: 6,
        name: 'Kontakt',
       url: 'admin/benutzerkonto'
      },
     {
       id: 7,
       name: 'Abmelden',
      url: 'admin/benutzerkonto'
     }
    ]
  };


handleMenuClick = (e) => {
    if (e.key === '1') {
        this.props.history.push(`/admin/benutzerkonto`);
    } else if (e.key === '2') {
        this.handleLogout();
    }
};

  handleIsOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  closeSideBar = () => {
    this.setState({ isOpen: false });
  }

  handleLogout = () => {
    removeToken();
//    setUser(undefined);
    this.props.history.push("/anmelden", { replace: true });
  };

  render() {
    const {   handleChange, navigationData, editorNavigationData, experience, searchText, filterAdvancedHighlighted } = this.props;
    const { adminActive, isOpen } = this.state;
    const screenWidth = document.getElementById("root").clientWidth;
    const isMobile = screenWidth < 1194;

    const navFirstRow = navigationData;
    return (<>
    { isMobile && <Menu
        isOpen={isOpen}
        onOpen={this.handleIsOpen}
        onClose={this.handleIsOpen}
    right={true}>
                    {navigationData &&
                                        navigationData.map(({ id, name, url }, i) => (
                                            <Link
                                              onClick={() => { this.props.changeExperience(name); this.closeSideBar(); }}
                                              to={{ pathname: `/${url}` }}
                                              key={"l--" + id}
                                              className="link"
                                            >
                                                <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf((name || "").toLowerCase()) > -1 || (this.props.history.location.pathname === "/" && name === 'Suchen')) ? " iwnav-current" : "")} key={id}>
                                                  <span className="label"> {name}</span>
                                                </li>
                                            </Link>
                                        ))}
                    {  adminActive && (editorNavigationData &&
                              <>
                              <Divider />

                              {editorNavigationData.map(({ id, name, url }, i) => {

                              return name !== 'Abmelden' ? <Link
                                                                                           onClick={() => { this.props.changeExperience(name); this.closeSideBar(); }}
                                                                                           to={{ pathname: `/${url}` }}
                                                                                           key={"l--" + id}
                                                                                           className="link"
                                                                                         ><div className="sign-in">
                                                                                                           <SioAvatar handleMenuClick={this.handleMenuClick} />
                                                                                                      </div> </Link>
                                :
                                 <Link
                                   onClick={() => { this.props.changeExperience(name); this.closeSideBar(); }}
                                   to={{ pathname: `/${url}` }}
                                   key={"l--" + id}
                                   className="link"
                                 >
                                   <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf((name || "").toLowerCase()) > -1 || (this.props.history.location.pathname === "/" && name === 'Suchen')) ? " iwnav-current" : "")} key={id}>
                                                           <span className="label"> aa{name}</span>
                                                         </li>
                                 </Link> }
                             )})}
                             </>
                             ) }
                             { !adminActive &&  <>
                             <Divider />
                             <Link
                                   onClick={() => { this.props.changeExperience('signin'); this.closeSideBar(); }}
                                   to={{ pathname: `/anmelden` }}
                                   key={"l--00" }
                                   className="link"
                                 >
                                   <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf('sign') > -1) ? " iwnav-current" : "")} key={102}>
                                                            <span className="label"> Anmelden</span>
                                                         </li>

                                </Link></>}

                  </Menu> }
      <div className="header-wrap">
        <div style={{ height: isMobile ? 92 : 134 }} className="header-wrapper extended navbar-fixed-top">
          <div className="header-left">
            <div className="nav">
              <div className="first-line">
               <div className="second-line-wrapper">
                <Link className="logo_a" to={{ pathname: `/` }}>
                  <div className="logoWrapper">
                    <img id="logo" src={Logo} alt="ICW Prüfungsplatform" />
                  </div>
                  <div className="logoLabels">
                    <span className="logoMain">ICW Portal</span><br/>
                    <span className="logoSubtitle">Anerkennungs- und Zertifizierungsstelle</span>
                  </div>
                </Link>
                <div id="topSearch" style={{ opacity: 0 }} className="search-line-wrapper">
                <div className="select-city">

                </div>
                <div className="select-experience">
                  <input
                    type="text"
                    placeholder="Suchen"
                    onChange={this.changeSearchText}
                    value={this.props.searchText}
                  />
                  <i className="fas fa-search" />
                </div>
               </div>
               </div>
              </div>
              <div style={{ display: isMobile ? 'none' : 'inherit', marginBottom: isMobile ? '-48px' : 'inherit' }} className="second-line">

               <div className={"second-line adminNav" + (adminActive ? " adminNav--active" : "")}>
                                  <div className="second-line-wrapper">
                                       <div className="best-picked">
                                           <ul className="iwnav-channels">
                                         { (editorNavigationData &&
                                                 editorNavigationData.map(({ id, name, url }, i) => { return (
                                                 name !== 'Abmelden' ? <Link
                                                                                                                                            onClick={() => { this.props.changeExperience(name); this.closeSideBar(); }}
                                                                                                                                            to={{ pathname: `/${url}` }}
                                                                                                                                            key={"l--" + id}
                                                                                                                                            className="link"
                                                                                                                                          ><div className="sign-in">
                                                                                                                                                            <SioAvatar handleMenuClick={this.handleMenuClick} />
                                                                                                                                                       </div> </Link>
                                                                                 :
                                                    <Link
                                                      onClick={() => { this.props.changeExperience(name); }}
                                                      to={{ pathname: `/${url}` }}
                                                      key={"l--" + id}
                                                      className="link"
                                                    >
                                                      <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf((name || "").toLowerCase()) > -1 || (this.props.history.location.pathname === "/" && name === 'Suchen')) ? " iwnav-current" : "")} key={id}>
                                                                              <span className="label"> s{name}</span>
                                                                            </li>
                                                    </Link>
                                                )})) }
                                           </ul>
                                       </div>
                                   </div>
                               </div>
               { screenWidth < 948 && <div className="second-line">
                 <div className="second-line-wrapper">
                  <div className="support support--second" style={{ marginLeft: screenWidth < 948 ? 0 : '12%'}}>
                  <ul className="iwnav-channels">
                                                           {editorNavigationData &&
                                                             editorNavigationData.map(({ id, name, url }, i) => (
                                                                 <Link
                                                                   onClick={() => { this.props.changeExperience(name); }}
                                                                   to={{ pathname: `/${url}` }}
                                                                   key={"l--" + id}
                                                                   className="link"
                                                                 >
                                                                   <li className={"iwnav-mainnav-level1" + ((this.props.history.location.pathname.indexOf((name || "").toLowerCase()) > -1 || (this.props.history.location.pathname === "/" && name === 'Suchen')) ? " iwnav-current" : "")} key={id}>
                                                                                           <span className="label"> sss{name}</span>
                                                                                         </li>
                                                                 </Link>
                                                             ))}
                                                             </ul>
                                                     <div className={"i-button"} onClick={ () => this.props.history.push(`/admin/aktionen`) }>Inserieren</div>
                                    <p>
                                        <a
                                            onClick={ () => this.props.history.push(`/anmelden`) }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style={{ textDecoration: 'none' }}
                                            >
                                              <div className="sign-in">
                                                   <SioAvatar handleMenuClick={this.handleMenuClick} />
                                              </div>
                                        </a>
                                      </p>
                                  </div>
                  </div>
                  </div>
                   }
              </div>
            </div>

          </div>
        </div>
      </div>
      </>
    );
  }
}

const options = [
  { value: 'Veranstaltung', label: 'Veranstaltung', route: '/veranstaltungen' },
  { value: 'Referent', label: 'Referent', route: '/referenten'  },
  { value: 'Hersteller', label: 'Hersteller', route: '/hersteller'  },
  { value: 'Produkt', label: 'Produkte', route: '/hersteller'  },
  { value: 'Versorgungspartner', label: 'Versorgungspartner', route: '/versorgungspartner'  },
];

const optionsHome = [
  { label: 'Ich suche nach Veranstaltungen', value: 'Veranstaltung', route: '/veranstaltungen' },
  { label: 'Ich suche nach Referenten', value: 'Referent', route: '/referenten'  },
  { label: 'Ich suche nach Herstellern', value: 'Hersteller', route: '/hersteller'  },
  { label: 'Ich suche nach Produkte', value: 'Produkte', route: '/hersteller'  },
  { label: 'Ich suche nach Versorgungspartner', value: 'Versorgungspartner', route: '/versorgungspartner'  },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'red' : '#727272',
    padding: 10,
    cursor: 'pointer',
    background: state.isSelected ? 'white' : 'white',
    fontSize: '13px',
    textAlign: 'left',
    width: 120
  }),
  control: () => ({
    width: 150,
    display: 'flex',
    fontSize: '14px',
    marginTop: '10px',
    paddingLeft: '5px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};

const smallSearchbar = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: 'none',
    color: state.isSelected ? 'red' : '#727272',
    padding: 10,
    cursor: 'pointer',
    background: state.isSelected ? 'white' : 'white',
    fontSize: '15px',
    textAlign: 'left',
    paddingLeft: '20px',
    width: 150
  }),
  control: () => ({
    width: 160,
    display: 'flex',
    fontSize: '14px',
    marginLeft: '15px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};

class Searchbar extends Component {

  changeUrl = url => {
    this.props.history.push(`/veranstaltungen/${url}`);
  };

  render() {
    const { history,selectedCity, selectedOption, handleChange } = this.props;
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    textAlign: 'left',
    paddingTop: 10,
    paddingBottom: 8,
    marginLeft: 14,
    width: '300px', // Set the width here
  }),
};

  const atHome = (this.props.history.location.pathname === "/");
  const atVeranstaltungen = (this.props.history.location.pathname === "/veranstaltungen");
  const atVersorgungspartner = (this.props.history.location.pathname === "/versorgungspartner");
  const atReferenten = (this.props.history.location.pathname === "/referenten");
  const atHersteller = (this.props.history.location.pathname === "/hersteller");

    if (selectedCity) {
      return (
        <Select
          styles={this.props.style}
          placeholder={selectedCity}
          value={selectedOption}
          onChange={handleChange}
          options={options}
          className="city-select-dropdown"
        />
      );
    } else {
      return (<>
        { atHome ? <div className={"fullWidthSelect"} ><Select
           styles={customStyles}
          value={optionsHome.find(o => o.value === selectedOption)}
          onChange={handleChange}
          options={optionsHome}
          className="city-select-dropdown city-select-dropdown-home--new"
        /></ div> : atVeranstaltungen ? <Select
                                           styles={this.props.style}
                                           value={{label: "Veranstaltung", value: "Veranstaltung"}}
                                           onChange={handleChange}
                                           options={options}
                                           className="city-select-dropdown veranstaltungen"
                                         /> : atReferenten
                                          ? <Select styles={this.props.style}
                                                                value={{label: "Referenten", value: "Referenten"}}
                                                                onChange={handleChange}
                                                                options={options}
                                                                className="city-select-dropdown veranstaltungen"
                                                              />  : atHersteller
                                                                                                            ? <Select styles={this.props.style}
                                                                                                                                  value={{label: "Produkte", value: "Produkte"}}
                                                                                                                                  onChange={handleChange}
                                                                                                                                  options={options}
                                                                                                                                  className="city-select-dropdown veranstaltungen"
                                                                                                                                />  : atVersorgungspartner
                                                                                                            ? <Select styles={this.props.style}
                                                                                                                                  value={{label: "Versorgungspartner", value: "Versorgungspartner"}}
                                                                                                                                  onChange={handleChange}
                                                                                                                                  options={options}
                                                                                                                                  className="city-select-dropdown veranstaltungen versorgungspartner"
                                                                                                                                /> : null
                                          }
        </>
      );
    }
  }
}

export default withRouter(HeaderWrapper);
