import React, { useEffect, useState, useContext, useRef } from "react";
import { Radio, Divider, List, Checkbox, Button, Input, Form, Switch, Space, Tag, Select, Alert, Card, message, Collapse } from 'antd';

import { EyeOutlined, DeleteOutlined, PlusOutlined, CommentOutlined, HistoryOutlined, DoubleRightOutlined, FormOutlined, CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import DraggableModal from './DraggableModal';
import { API } from "../constant";
import { getToken, formatChanges } from "../helpers";
import { Aktenaudit } from "../configs/Aktenaudit.js";
import { Pruefungsaudit } from "../configs/Pruefungsaudit.js";
import { Rezertifizierungsausdit } from "../configs/Rezertifizierungsausdit.js";
import { Seminaraudit } from "../configs/Seminaraudit.js";

const { Option } = Select;

const StatusEnum = {
  NOT_SUBMITTED: 'Noch nicht eingereicht',
  SUBMITTED_IN_PROGRESS: 'Eingereicht - in Bearbeitung',
  SUBMITTED_REJECTED: 'Eingereicht - abgelehnt',
  SUBMITTED_ACCEPTED: 'Eingereicht - angenommen',
};

const KurseList = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isKommentarModalVisible, setIsKommentarModalVisible] = useState(false);
    const [isRevision, setIsRevision] = useState(false);
  const [currentKurs, setCurrentKurs] = useState(null);
  const [showAll, setShowAll] = useState(false)
  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openApproveAkzeptieren, setOpenApproveAkzeptieren] = useState(false);
  const [openAblehnen, setOpenAblehnen] = useState(false);
  const [openStorno, setOpenStorno] = useState(false);


  const location = useLocation();
  useEffect(() => {
   if (
     location.state
   ) {
        if (location.state && location.state.openApproveAkzeptieren) {
          setOpenApproveAkzeptieren(location.state.openApproveAkzeptieren);
        }

        if (location.state && location.state.openAblehnen) {
          setOpenAblehnen(location.state.openAblehnen);
        }
    }
    // Update the ref with the current location state
  }, [location.pathname]);

  const showModal = (id) => {
    setOpen(id);
  };

    const showModalApprove = (id) => {
      setOpenApprove(id);
    };

    const showModalAblehnen = (id, kurs) => {
        if (kurs) {
            setOpenStorno(id);
        }
        setOpenAblehnen(id);
    }

  const handleOk = () => {
    setOpen(false);
  };
  const handleApproveOk = () => {
    setOpenApprove(false);
  };

  const handleApproveAkzeptierenOk = () => {
    setOpenApproveAkzeptieren(false);
  }

  const handleApproveAkzeptierenCancel = () => {
    setOpenApproveAkzeptieren(false);
  }

  const handleApproveCancel = () => {
    setOpenApprove(false);
  };
  const handleCancel = () => {
    setOpen(false);
    setOpenAblehnen(false);
  };

  const Auditors = props.auditorsData;
  const Kurse = props.veranstaltungenData;
  const showEditModal = (kurs) => {
    setCurrentKurs(kurs);
    setIsModalVisible(true);
    form.setFieldsValue(kurs);
  };

  const showKommentarModal = (kurs, stornmessage, isRevision) => {
    setCurrentKurs(kurs);
    if (stornmessage) {
          setCurrentKurs({ ...kurs, kommentar: stornmessage });
    }
    setIsRevision(!!isRevision);
    setIsKommentarModalVisible(true);
  };

  const handleEdit = (dozentid) => {
   props.history.push(`/admin/aktionen/Seminare/${dozentid}`)
  };


  const handleAkzeptieren = (id, kommentar = "", bewertung = "") => {

    const kursnummer = props.auditData.find(a => a.id == id).Kursnummer

        fetch(`${API}/audit-bogens/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
              body: JSON.stringify({
              data: {
               bewertung: bewertung !== '' ? bewertung : undefined,
               kommentar: kommentar,
               status: "Eingereicht - angenommen" }}),
            })
              .then(response => response.json())
              .then(data => { console.log(data);
                  if (data.error) {
                  } else {

   let auditor = props.auditorsData.find((auditor) => (auditor.AuditingKursnummer || "").split(",").includes(kursnummer));

    let currentAudits = (auditor.AuditingKursnummer || "").split(",") || [];
    let auditInfo = auditor.auditinfo || {};

    currentAudits = currentAudits.filter((k) => k !== kursnummer);
     fetch(`${API}/users/${auditor.id}/auditing-kursnummer`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                  },
                    body: JSON.stringify({ data: {auditinfo: auditInfo, AuditingKursnummer: currentAudits.join(",") } }),
                })
                      .then(response => response.json())
                      .then(data => { console.log(data);
                          if (data.error) {
//                              message("", "Es ging etwas schief");
                          } else {
                              if (kursnummer === "") {
                                message.success("Auditor zugewiesen.");
                              } else {
                                message.success("Auditorzuweisung entfehrnt.");
                              }
                              props.history.push('/admin/aktionen/Auditorverwaltung', {openApproveAkzeptieren: false, openAblehnen: false })
                              window.location.reload();
                          }
                      })
                      .catch((error) => { console.error('Error:', error);
//                       message("", "Es ging etwas schief");
                       })


                      props.history.push('/admin/aktionen/Auditorverwaltung', {openApproveAkzeptieren: false, openAblehnen: false })
                      window.location.reload();

                  }
              })
              .catch((error) => { console.error('Error:', error);
//                       message("", "Es ging etwas schief");
               });
      };

  const handleDeny = (id, kommentar = "", bewertung = "") => {
        fetch(`${API}/audit-bogens/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
              body: JSON.stringify({ data: { bewertung: bewertung !== '' ? bewertung : undefined, kommentar: kommentar, status: "Eingereicht - abgelehnt" }}),
            })
              .then(response => response.json())
              .then(data => { console.log(data);
                  if (data.error) {
//                              message("", "Es ging etwas schief");
                  } else {
//                              message("", "Kurs gelöscht. .");
//
                      props.history.push('/admin/aktionen/Auditorverwaltung', {openApproveAkzeptieren: false, openAblehnen: false })
                      window.location.reload();
                  }
              })
              .catch((error) => { console.error('Error:', error);
//                       message("", "Es ging etwas schief");
               });
      };

    const handleApprove = (id, kursnummer, auditorString, doRemove, kommentar = "", bewertung = "", auditArt, auditTyp) => {

    const auditor = JSON.parse(auditorString);

    let currentAudits = (auditor.AuditingKursnummer || "").split(",") || [];
    let auditInfo = auditor.auditinfo || {};

    if (doRemove)   {
        currentAudits = currentAudits.filter((k) => k !== kursnummer);

        if (auditInfo[kursnummer]) {
            auditInfo[kursnummer].storniert = new Date().getTime();
            auditInfo[kursnummer].kommentar = kommentar;
        } else {
            auditInfo[kursnummer] = { bewertung: bewertung !== '' ? bewertung : undefined, storniert: new Date().getTime(), kommentar: kommentar };
        }

    } else {
        currentAudits.push(kursnummer);

        if (auditInfo[kursnummer]) {
            auditInfo[kursnummer].auftragsdatum = new Date().getTime();
            if (auditArt) auditInfo[kursnummer].auditArt = auditArt;
            if (auditTyp) auditInfo[kursnummer].auditTyp = auditTyp;
        } else {
            auditInfo[kursnummer] = { auftragsdatum: new Date().getTime() };
            if (auditArt) auditInfo[kursnummer].auditArt = auditArt;
            if (auditTyp) auditInfo[kursnummer].auditTyp = auditTyp;
        }
    }

 fetch(`${API}/users/${auditor.id}/auditing-kursnummer`, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                  },
                    body: JSON.stringify({ data: {auditinfo: auditInfo, AuditingKursnummer: currentAudits.join(",") } }),
                })
                      .then(response => response.json())
                      .then(data => { console.log(data);
                          if (data.error) {
//                              message("", "Es ging etwas schief");
                          } else {
                              if (kursnummer === "") {
                                message.success("Auditor zugewiesen.");
                              } else {
                                message.success("Auditorzuweisung entfehrnt.");
                              }
props.history.push('/admin/aktionen/Auditorverwaltung', {openApproveAkzeptieren: false, openAblehnen: false })

                              window.location.reload();
                          }
                      })
                      .catch((error) => { console.error('Error:', error);
//                       message("", "Es ging etwas schief");
                       });
  };

  const handleAdd = () => {
  };

  return (
    <div>
    <div style={{textAlign: 'left' }}></div>
    <Collapse items={[{key: '1',label: <Divider orientation="left">Auditaufträge</Divider>,children: <>
         <Button type="primary" icon={<PlusOutlined />}
         onClick={() => showModalApprove(true) }
         style={{textAlign: 'left', marginBottom: 16 }}>
           Neuen Auditor zuweisen
         </Button>
         <List
           dataSource={Auditors.filter(kurs => kurs.AuditingKursnummer !== null && kurs.AuditingKursnummer !== "")}
           renderItem={(kurs) => {

const fList = Object.keys(kurs.auditinfo || {});
           const userKurse = kurs.AuditingKursnummer.split(",");
           return (
             <Card
               bordered={false}
               style={{ marginBottom: 16 }}
             >
             {Kurse.filter(k => userKurse.includes(k.Kursnummer)).map(kursA => {

               const auditBogen = (props.auditData.find((audit) => audit.Kursnummer === kursA.Kursnummer));


   const ksn = (kursA || {}).Kursnummer || "";
   const auftragsdatum = ((kurs.auditinfo || {})[ksn] || {}).auftragsdatum;
   const auditArt = ((kurs.auditinfo || {})[ksn] || {}).auditArt;
   const auditTyp = ((kurs.auditinfo || {})[ksn] || {}).auditTyp;
   const storniertdatum = ((kurs.auditinfo || {})[ksn] || {}).storniert;
   const kommentarstorniert = ((kurs.auditinfo || {})[ksn] || {}).kommentar;


   let formattedDate = '';
   let sformattedDate = '';

   if (auftragsdatum) {
       const date = new Date(auftragsdatum);

       // Get the day, month, and year
       const day = String(date.getUTCDate()).padStart(2, '0');
       const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
       const year = String(date.getUTCFullYear()).slice(-2);

       // Format the date as dd-mm-yy
       formattedDate = `${day}.${month}.${year}`;
   }
               return ( <List.Item
                 actions={
                 sformattedDate ? [] : (
                 (auditBogen || true) ? [ // wenn storniert dann mit kommentar und weiterhin in liste nur mit neuem status "STORNIERT"
                   <Button icon={<CloseCircleOutlined />} danger onClick={() => { showModalAblehnen(kursA.Kursnummer, JSON.stringify(kurs))}}>Zugriffsrechte stornieren...</Button>,
                 ] : [
                    <Button icon={<CloseCircleOutlined />} danger onClick={() => handleApprove("",kursA.Kursnummer, JSON.stringify(kurs), true)}>Zugriffsrechte stornieren...</Button>,
                    ])}
               >
                 <List.Item.Meta style={{     textAlign: 'left' }} title={<span>{(kurs.Name)}  {<DoubleRightOutlined />}   {(((kursA || {}).kursinformation || {})["Titel der Veranstaltung (Wundbezogen)"] + ((kursA || {}).Kursnummer ? (" (" + (kursA || {}).Kursnummer + ")") : ""))}

                 { !sformattedDate && formattedDate && <span style={{ color: '#eb008c', paddingLeft: '2rem' }}><Tag color="#1fcd42">{auditArt}</Tag> <Tag color="#1f59cd">{auditTyp}</Tag> Auftragsdatum: <i>{formattedDate}</i></span> }
                 { sformattedDate && sformattedDate && <span style={{ paddingLeft: '1rem' }}><Tag color="#cd201f">storniert am {sformattedDate} </Tag>&nbsp;&nbsp;{ kommentarstorniert && <Button icon={<CommentOutlined />} onClick={() => showKommentarModal(kursA)}>Kommentar</Button>}</span> }
                 </span>}  />

               </List.Item>)
             })}
             {Kurse.filter(k =>  fList.includes(k.Kursnummer)).filter(kursA => !!((kurs.auditinfo || {})[((kursA || {}).Kursnummer || "")] || {}).storniert).map(kursA => {

                            const auditBogen = (props.auditData.find((audit) => audit.Kursnummer === kursA.Kursnummer));





                const ksn = (kursA || {}).Kursnummer || "";
                const auditArt = ((kurs.auditinfo || {})[ksn] || {}).auditArt;
                const auditTyp = ((kurs.auditinfo || {})[ksn] || {}).auditTyp;
                const auftragsdatum = ((kurs.auditinfo || {})[ksn] || {}).auftragsdatum;
                const storniertdatum = ((kurs.auditinfo || {})[ksn] || {}).storniert;
                const kommentarstorniert = ((kurs.auditinfo || {})[ksn] || {}).kommentar;


                let formattedDate = '';
                let sformattedDate = '';

                if (auftragsdatum) {
                    const date = new Date(auftragsdatum);

                    // Get the day, month, and year
                    const day = String(date.getUTCDate()).padStart(2, '0');
                    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
                    const year = String(date.getUTCFullYear()).slice(-2);

                    // Format the date as dd-mm-yy
                    formattedDate = `${day}.${month}.${year}`;
                }

                if (storniertdatum) {
                      const sdate = new Date(storniertdatum);

                      // Get the day, month, and year
                      const sday = String(sdate.getUTCDate()).padStart(2, '0');
                      const smonth = String(sdate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
                      const syear = String(sdate.getUTCFullYear()).slice(-2);

                      // Format the date as dd-mm-yy
                      sformattedDate = `${sday}.${smonth}.${syear}`;
                  }
                            return ( <List.Item
                              actions={
                              sformattedDate ? [] : (
                              (auditBogen || true) ? [ // wenn storniert dann mit kommentar und weiterhin in liste nur mit neuem status "STORNIERT"
                                <Button icon={<CloseCircleOutlined />} danger onClick={() => { showModalAblehnen(kursA.Kursnummer, JSON.stringify(kurs))}}>Zugriffsrechte stornieren...</Button>,
                              ] : [
                                 <Button icon={<CloseCircleOutlined />} danger onClick={() => handleApprove("",kursA.Kursnummer, JSON.stringify(kurs), true)}>Zugriffsrechte stornieren...</Button>,
                                 ])}
                            >
                              <List.Item.Meta style={{     textAlign: 'left' }} title={<span>{(kurs.Name)}  {<DoubleRightOutlined />}   {(((kursA || {}).kursinformation || {})["Titel der Veranstaltung (Wundbezogen)"] + ((kursA || {}).Kursnummer ? (" (" + (kursA || {}).Kursnummer + ")") : ""))}

                              { !sformattedDate && formattedDate && <span style={{ color: '#eb008c', paddingLeft: '2rem' }}><Tag color="#1fcd42">{auditArt}</Tag> <Tag color="#1f59cd">{auditTyp}</Tag> Auftragsdatum: <i>{formattedDate}</i></span> }
                              { sformattedDate && sformattedDate && <span style={{ paddingLeft: '1rem' }}><Tag color="#cd201f">storniert am {sformattedDate} </Tag>&nbsp;&nbsp;
                              { kommentarstorniert && <Button icon={<CommentOutlined />} onClick={() => showKommentarModal(auditBogen, kommentarstorniert)}>Kommentar</Button>}
                              </span> }
                              </span>}  />

                            </List.Item>)
                          })}
             </Card>
           )}}
     locale={{ emptyText: 'Keine Auditoraufträge gefunden' }}
         /></>}]}>

    </Collapse>
    <Collapse items={[{key: '2',label: <Divider orientation="left">Auditberichte</Divider>,children: <>
        <List
            dataSource={props.auditData.filter(kurs => !!kurs.status)}
            renderItem={(auditBogen) => {

//            const userKurse = kurs.AuditingKursnummer.split(",");
            const kursA = Kurse.find(kurs => kurs.Kursnummer === auditBogen.Kursnummer);
            const kurs = Auditors.find(kurs => kurs.id === auditBogen.createdby);
            if (!kurs) return null;
            return (
              <Card
                bordered={false}
                style={{ marginBottom: 16 }}
              >
              <List.Item
                  actions={
                  auditBogen.status === StatusEnum.SUBMITTED_REJECTED ?
                  [
                    <Button icon={<EyeOutlined />} onClick={() =>  props.history.push(`/admin/aktionen/Auditbericht/${auditBogen.id}`) } >Anzeigen</Button>,
                  ] :
                  auditBogen.status === StatusEnum.SUBMITTED_ACCEPTED ?
                  [
                    <Button icon={<EyeOutlined />} onClick={() =>  props.history.push(`/admin/aktionen/Auditbericht/${auditBogen.id}`) } >Anzeigen</Button>,
                  ] :
                  [
                    <Button icon={<EyeOutlined />} onClick={() =>  props.history.push(`/admin/aktionen/Auditbericht/${auditBogen.id}`) } >Anzeigen</Button>,
                    <Button icon={<CloseCircleOutlined />} danger type="primary" onClick={() => showModalAblehnen(auditBogen.id)}>Auditbericht Ablehnen</Button>,
                    <Button icon={<CheckCircleOutlined />} style={{ color: 'white', background: '#52c41a' }} onClick={() => setOpenApproveAkzeptieren(auditBogen.id)}>Auditbericht akzeptieren</Button>,
                  ]
                  }
                >
                  <List.Item.Meta title={<span>{(kurs.Name)}  {<DoubleRightOutlined />}   {(((kursA || {}).kursinformation || {})["Titel der Veranstaltung (Wundbezogen)"] + ((kursA || {}).Kursnummer ? (" (" + (kursA || {}).Kursnummer + ")") : ""))}</span>}  />

                { auditBogen && auditBogen.status && <List.Item.Meta style={{ paddingLeft: 4, paddingRight: 12 }}  title={<Alert
                                  message={<span>Bericht: {(auditBogen || {}).status}
                                  { auditBogen.kommentar && <Button icon={<CommentOutlined />} onClick={() => showKommentarModal(auditBogen, auditBogen.kommentar)}>Kommentar</Button>}
                                  {props.isICW && (((auditBogen || {}).profilinformation || {}).revision && <Button icon={<HistoryOutlined />} onClick={() => showKommentarModal(auditBogen, undefined, true)}>Änderungen</Button>)}
                                  </span>}
                                  type={
                                    auditBogen.status === StatusEnum.NOT_SUBMITTED
                                      ? 'info'
                                      : auditBogen.status === StatusEnum.SUBMITTED_IN_PROGRESS
                                      ? 'warning'
                                      : auditBogen.status === StatusEnum.SUBMITTED_REJECTED
                                      ? 'error'
                                      : 'success'
                                  }
                                /> } /> }
                { !auditBogen && <List.Item.Meta style={{ paddingLeft: 4, paddingRight: 12 }}  title={<Alert
                                  message={<><span>Bericht: {StatusEnum.NOT_SUBMITTED} </span>&nbsp;&nbsp; </>}
                                  type={'info'}
                                /> } /> }
                </List.Item>
              </Card>
            )}}
      locale={{ emptyText: 'Keine Auditoraufträge gefunden' }}
          />
          </>}]}>

              </Collapse>
            <DraggableModal
                closable={true}
                open={openAblehnen}
                title={ openStorno ? "Begründung der Stornierung" : "Begründung der Ablehnung"}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
              > <Form
                   name="nest-messages"
                   onFinish={(values) => {
                      if (openStorno){
                        const foundAuditor = props.auditorsData.find((auditor) => (auditor.AuditingKursnummer || "").split(",").includes(openAblehnen));
                        handleApprove(openAblehnen, openAblehnen, JSON.stringify(foundAuditor), true, values.kommentar, values.bewertung);
                      } else {
                        handleDeny(openAblehnen, values.kommentar, values.bewertung);
                      }
                  }}
                   style={{ maxWidth: 600 }}
                 >
                   <Form.Item name={'kommentar'} label="Begründung">
                     <Input.TextArea />
                   </Form.Item>
                   { openStorno && <Form.Item name={'bewertung'} label="Bewertung">
                    <Radio.Group>
                     <Space direction="vertical">
                       <Radio value={"0 = Alle geprüften Qualitätsmerkmale voll erfüllt"}>0 = Alle geprüften Qualitätsmerkmale voll erfüllt</Radio>
                       <Radio value={"1 = Geringe Abweichungen"}>1 = Geringe Abweichungen</Radio>
                       <Radio value={"2 = Qualitätsmängel mit der Bitte um Korrektur"}>2 = Qualitätsmängel mit der Bitte um Korrektur</Radio>
                       <Radio value={"3 = Erhebliche Qualitätsmängel mit dringendem Korrekturbedarf"}>3 = Erhebliche Qualitätsmängel mit dringendem Korrekturbedarf</Radio>
                       <Radio value={"4 = Kurs gesperrt weitere Absprachen erforderlich"}>4 = Kurs gesperrt weitere Absprachen erforderlich</Radio>
                      </Space>
                    </Radio.Group>
                   </Form.Item>}
                   <Form.Item wrapperCol={{ span: 16 , offset: 8 }}>
                     <Button type="primary" htmlType="submit">
                       { openStorno ? "Mit obiger Begründung stornieren" : "Mit obiger Begründung ablehnen"}
                     </Button>
                   </Form.Item>
                 </Form>
              </DraggableModal>


             <DraggableModal
                closable={true}
                open={openApproveAkzeptieren}
                title="Auditbericht akzeptieren"
                onOk={handleApproveAkzeptierenOk}
                onCancel={handleApproveAkzeptierenCancel}
                footer={null}
                style={{ minWidth: 720 }}
              > <Form
                   name="nest-messages"
                   onFinish={(values) => {
                      handleAkzeptieren(openApproveAkzeptieren, values.kommentar, values.bewertung);
                  }}
                   style={{ maxWidth: 600 }}
                 >
                   <Form.Item name={'kommentar'} label="Erforderliche Anpassung/ Fazit">
                     <Input.TextArea />
                   </Form.Item>
                   <Form.Item name={'bewertung'} label="Bewertung">
                    <Radio.Group>
                     <Space direction="vertical">
                       <Radio value={"0 = Alle geprüften Qualitätsmerkmale voll erfüllt"}>0 = Alle geprüften Qualitätsmerkmale voll erfüllt</Radio>
                       <Radio value={"1 = Geringe Abweichungen"}>1 = Geringe Abweichungen</Radio>
                       <Radio value={"2 = Qualitätsmängel mit der Bitte um Korrektur"}>2 = Qualitätsmängel mit der Bitte um Korrektur</Radio>
                       <Radio value={"3 = Erhebliche Qualitätsmängel mit dringendem Korrekturbedarf"}>3 = Erhebliche Qualitätsmängel mit dringendem Korrekturbedarf</Radio>
                       <Radio value={"4 = Kurs gesperrt weitere Absprachen erforderlich"}>4 = Kurs gesperrt weitere Absprachen erforderlich</Radio>
                      </Space>
                    </Radio.Group>
                   </Form.Item>
                   <Form.Item wrapperCol={{ span: 16 , offset: 8 }}>
                   <Button type="primary" htmlType="submit" icon={<CheckCircleOutlined />} style={{ color: 'white', background: '#52c41a' }}>
                   Akzeptieren</Button>
                   </Form.Item>
                 </Form>
              </DraggableModal>
      <DraggableModal
        title="Kurs bearbeiten"
        visible={isModalVisible}
        onOk={handleEdit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Bitte geben Sie einen Namen ein' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Bitte wählen Sie einen Status aus' }]}
          >
            <Select>
              <Option value={StatusEnum.NOT_SUBMITTED}>Anerkennung nicht gestartet</Option>
              <Option value={StatusEnum.SUBMITTED_IN_PROGRESS}>Anerkennung in Bearbeitung</Option>
              <Option value={StatusEnum.SUBMITTED_REJECTED}>Anerkennung abgelehnt</Option>
              <Option value={StatusEnum.SUBMITTED_ACCEPTED}>Anerkannt</Option>
            </Select>
          </Form.Item>
          {form.getFieldValue('status') === StatusEnum.SUBMITTED_REJECTED && (
            <Form.Item
              name="kommentar"
              label="Kommentar"
              rules={[{ required: true, message: 'Bitte geben Sie einen Kommentar ein' }]}
            >
              <Input.TextArea />
            </Form.Item>
          )}
        </Form>
      </DraggableModal>
      <DraggableModal
          title={ isRevision ? "Verlauf" : "Kommentar" }
          visible={isKommentarModalVisible}
          width={ isRevision ? "85vw" : "50vw" }
          onCancel={() => setIsKommentarModalVisible(false)}
          footer={[
            <Button key="close" onClick={() => setIsKommentarModalVisible(false)}>
              Schließen
            </Button>,
          ]}
        >
           { isRevision ? formatChanges(((currentKurs || {}).profilinformation || {}).revision, [ ...Seminaraudit, ...Aktenaudit, ...Pruefungsaudit ])  : <div dangerouslySetInnerHTML={{ __html: currentKurs ? currentKurs.kommentar : '' }} />}
      </DraggableModal>




    <DraggableModal
        closable={true}
        open={openApprove}
        title="Auditor zuweisen"
        onOk={handleApproveOk}
        onCancel={handleApproveCancel}
        footer={null}
      > <Form
           name="nest-messages"
           onFinish={(values) => {
              handleApprove(openApprove, values.Kursnummer, values.Auditor, undefined, undefined, undefined, values.Auditart, values.Audittyp)
          }}
           style={{ maxWidth: 600 }}
         >
         <Form.Item
            rules={[
                    {
                        required: true,
                        message: 'Ein Auditor muss angegeben werden.',
                    },
                ]}
            name={'Auditor'} label="Auditor">
              <Select placeholder="Bitte auswählen...">
                                   { Auditors.map((kurs) => (
                                     <Option key={kurs.id} value={JSON.stringify(kurs || {})}>{kurs.Name}</Option>
                                      ))}
                                                   </Select>
            </Form.Item>

         <Form.Item
            rules={[
                    {
                        required: true,
                        message: 'Eine Auditart muss angegeben werden.',
                    },
                ]}
            name={'Auditart'} label="Auditart">
              <Select placeholder="Bitte auswählen...">
                                   {  ["Regelaudit", "Überwachungsaudit", "Berücksichtigung von Auflagen vorangegangener Audits"].map((kurs) => (
                                     <Option key={kurs} value={kurs}>{kurs}</Option>
                                      ))}
                                                   </Select>
            </Form.Item>
<Form.Item
            rules={[
                    {
                        required: true,
                        message: 'Ein Audittyp muss angegeben werden.',
                    },
                ]}
            name={'Audittyp'} label="Audittyp">
              <Select placeholder="Bitte auswählen...">
                                   {   ["Aktenaudit", "Prüfungsaudit", "Seminaraudit"].map((kurs) => (
                                     <Option key={kurs} value={kurs}>{kurs}</Option>
                                      ))}
                                                   </Select>
            </Form.Item>
           <Form.Item
           rules={[
                   {
                       required: true,
                       message: 'Ein Seminar muss angegeben werden.',
                   },
               ]}
           name={'Kursnummer'} label="Seminar">
             <Select placeholder="Bitte auswählen...">
             { Kurse.filter(
                  (kurs) => typeof kurs.Kursnummer !== 'undefined' && kurs.Kursnummer !== null
             ).map((kurs) => (
               <Option key={kurs.id} value={kurs.Kursnummer}>{((kurs || {}).kursinformation || {})["Titel der Veranstaltung (Wundbezogen)"] + ((kurs || {}).Kursnummer ? (" (" + (kurs || {}).Kursnummer + ")") : "")}</Option>
                ))}
                             </Select>
           </Form.Item>

           <Form.Item wrapperCol={{ span: 16 , offset: 8 }}>
           <Button type="primary" htmlType="submit" icon={<CheckCircleOutlined />} style={{ color: 'white', background: '#52c41a' }}>
           Auditor zuweisen</Button>
           </Form.Item>
         </Form>
      </DraggableModal>

      <DraggableModal
          closable={true}
          open={open}
          title="Zugriffsrechte entzogen"
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
        <p>
            Hiermit werden dem Auditor die Zugriffsrechte des Seminars wieder entzogen.
        </p>
        </DraggableModal>
    </div>
  );
};

export default KurseList;
