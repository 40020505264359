import React, { Component } from 'react';
import { Button, Row, Col, Rate, Form, Input, Select, TreeSelect, Checkbox, DatePicker, Modal } from 'antd';

import localObject from 'antd/es/date-picker/locale/de_DE';
import ReactDOM from 'react-dom';
import { Link, withRouter } from 'react-router-dom';
import { actions, Table, Pagination }  from 'react-data-components';
import 'react-data-components/css/table-twbs.css';
import moment from 'moment';
import dayjs from 'dayjs';
import Autocomplete from './Autocomplete';
import DataTable from './Table/CustomDataTable';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';
import { CollectionCard } from './Collections';
import { VeranstaltungenSlider } from './TopVeranstaltungen';
import { API_BASE } from "../constant";
import { Left, Right } from './Arrows';

const { RangePicker } = DatePicker;
const { Option } = Select;

const attributes = {
  Name: "Attribute.String",
  Stadt: "Attribute.String",
  PLZ: "Attribute.Integer",
  Bundesland: [
    'Baden-Württemberg',
    'Bayern',
    'Berlin',
    'Brandenburg',
    'Bremen',
    'Hamburg',
    'Hessen',
    'Mecklenburg-Vorpommern',
    'Niedersachsen',
    'Nordrhein-Westfalen',
    'Rheinland-Pfalz',
    'Saarland',
    'Sachsen',
    'Sachsen-Anhalt',
    'Schleswig-Holstein',
    'Thüringen'
  ],
  Kategorie: [
    'Apotheke',
    'Ergo- und Logotherpiepraxis',
    'Klinik',
    'Rehaeinrichtung',
    'Facharzt',
    'Hausarzt/MVZ',
    'Pflegeheim',
    'Sozialdienst/Pflegedienst/SAPV',
    'Homecareunternehmen',
    'Wundzentrum',
    'Sanitätshaus',
    'Podologiepraxis',
    'Physiotherapiepraxis',
    'Hebamme',
    'Gesundheitskasse',
    'Beratungsstelle'
  ],
  Barrierefreiheit: "Attribute.Boolean",
 /* Bettenanzahl: "Attribute.Integer",
  Aufgabengebiete: [
    'Wundversorgung',
    'Stomaversorgung',
    'Enterale Versorgung',
    'Parenterale Versorgung',
    'Inkontinenzversorgung',
    'Tracheostomaversorgung',
    'V-Antibiose',
    'Sauerstofftherapie'
  ],
  Spezialbehandlungen: [
    'Plasmatherapie',
    'Lasertherapie',
    'Lichttherapie',
    'Ultraschalltherapie',
    'Sauerstofftherapie',
    'Kompressionsstrümpfe',
    'Bandagen',
    ''
  ],*/
};

const info = () => {
  Modal.info({
    title: 'Willkommen bei der Versorgungspartnersuche!',
    content: (
      <div style={{ marginBottom: -16 }}>
        Benutzen Sie die Filterfunktionen, um schnellstmöglich zu einem passenden Treffer zu kommen.
      </div>
    ),
    onOk() { localStorage.setItem("pp_filterAkkn", true); },
  });
};

function checkOverlap(dateRange1, dateRange2) {
    const start1 = dayjs(dateRange1[0]);
    const end1 = dayjs(dateRange1[1]);
    const start2 = dayjs(dateRange2[0]);
    const end2 = dayjs(dateRange2[1]);

    return start1.isBefore(end2) && end1.isAfter(start2);
}

const keyMap = {
  "Bettenanzahl": "Bettenanzahl (mindestens)",
  "Behandlungsmoeglichkeiten": "Behandlungsmöglichkeiten",
  "BehandelteKrankheiten": "Behandelte Krankheiten",

}

const columns = [
  { title: '', prop: 'Logo', render: (Bild) => <div className="city-card-img city-card-img--table" style={{ backgroundImage: `url(${API_BASE + ((((Bild || {}).data || {}).attributes || {}).url || "/uploads/pmp_ac1da8c4a1.png" )})` }}></div> },
  { title: 'Name', prop: 'Name', render : (label) => <span className="label">{label}</span> },
  { title: 'Kategorie', prop: 'Kategorie', render : (label) => <span className="label">{label}</span> },
  { title: 'Ort', prop: 'Stadt', sortable: true, render: (label) => <span className="label">{label}</span> }
]

const columnsExtended = [ ...columns,
  { title: 'Aufgabengebiete', prop: 'Aufgabengebiete', render: (ICW) => <span>{(ICW || []).join(", ") || "N/V"}</span> },
];


class SelectableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  };

  deSelectRow = (row) => {
    this.setState({ selected: '' });
  };

  buildRowOptions = (row) => {
    return {
        onClick: () => {
            this.props.history.push(`/versorgungspartner/${row.id}`);
        },
//      onMouseOver: () => this.selectRow(row),
//      onMouseOut: () => this.deSelectRow(row),
      className: this.state.selected === row.id ? 'active-row' : null,
    };
  };

  render() {
    return (
      <DataTable buildRowOptions={this.buildRowOptions} {...this.props} />
    );
  }
}

class Veranstaltungen extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      selected: '',
      filterObj: {},
      vOrt: false,
      advancedFilterShown: false,
      filteredVeranstaltungenData: []
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    let typingTimer;
    const inputField = document.getElementById("veranstaltungsort");

    if (localStorage.getItem("pp_filterAkkn") != "true") info();
    this.setState({ filteredVeranstaltungenData: this.props.veranstaltungenData.filter( v =>  v.type === "Versorgungspartner") });
  }

  componentDidUpdate(prevProps, prevState) {
  // check filterAdvancedHighlighted
    if (prevProps.filterAdvancedHighlighted !== this.props.filterAdvancedHighlighted && !this.props.filterAdvancedHighlighted) {
//        return this.setState({ filteredVeranstaltungenData: this.props.veranstaltungenData.filter( v =>  v.type === "Referent") });
    }

    if ((prevProps.veranstaltungenData || []).length !== (this.props.veranstaltungenData || []).length) {
        this.setState({ filteredVeranstaltungenData: this.props.veranstaltungenData.filter( v =>  v.type === "Versorgungspartner") });
    }
    if (prevState.filterObj !== this.state.filterObj || (JSON.stringify(prevState.filterObj || {}) !== JSON.stringify(this.state.filterObj || {}))){
        this.filterByObj();
    }

  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  }

  handleRowClick = (rowData) => {
    // Perform actions based on the clicked row data
  };

    updateFilterByObj = (e, v) => {
        this.setState({ filterObj: v });
    }

  filterByObj = () => {
    const { veranstaltungenData, filterAdvancedHighlighted } = this.props;
    const { vOrt } = this.state;

    if(!filterAdvancedHighlighted) return;

    const filterObj = this.state.filterObj;
    const filterKeysWithValues = Object.keys(filterObj).filter(key => filterObj[key] !== undefined && filterObj[key] !== null && filterObj[key] !== "" && ((filterObj[key] || []).length !== 0 || filterObj[key] === false))
    const filteredReferentenData = this.props.veranstaltungenData.filter( v =>  v.type === "Versorgungspartner")
    .filter( v => filterKeysWithValues.every( key => {
//        if (Array.isArray(filterObj[key]) ) {
            if (Array.isArray(v[key])) {
                return Array.isArray(filterObj[key]) ? (filterObj[key].some(vk => v[key].includes(vk))) : ([filterObj[key]].some(vk => v[key].includes(vk)));
            }
//        }

        if (key.indexOf("Bettenanzahl") > -1 && v[key]) {
           return v[key] <= filterObj[key];
        }

        if (typeof filterObj[key] === 'string' && typeof v[key] === 'string') {
            return v[key].toUpperCase().indexOf(filterObj[key].toUpperCase()) > -1;
        }

        return v[key] === filterObj[key];
    }));

    // wait for Veranstaltungsort to stop typing


    this.setState({ filteredVeranstaltungenData: filteredReferentenData });

  };

  render() {

  const { veranstaltungenData, filterAdvancedHighlighted, form } = this.props;
  const { advancedFilterShown, filteredVeranstaltungenData } = this.state;
    return (
      <>



<div className={"search-bar-div search-bar-div--filter " + (filterAdvancedHighlighted ? "search-bar-div--active" : "search-bar-div--inactive")}> <div style={{ padding: '2em', width: '80%', height: 'auto' }} className="select-city-large ">
 <Form
   name="myForm"
   form={form}
   initialValues={{}}
   onValuesChange={(changedValues, allValues) => this.updateFilterByObj(changedValues, allValues)}
 >
   <Row gutter={24}>
     {Object.entries(attributes).map(([key, value]) => (
       <Col key={key} xs={24} sm={24} md={12} lg={12}>
         <Form.Item label={keyMap[key] || key} name={key}>
           {Array.isArray(value) ? (
             <Select>
               {value.map(option => (
                 <Option key={option} value={option}>
                   {option}
                 </Option>
               ))}
             </Select>
           ) : value === "Attribute.Integer" ? (
             <Input type="number" />
           ) : value === "Attribute.Boolean" ? (
             <Select>
               <Option value={true}>Ja</Option>
               <Option value={false}>Nein</Option>
             </Select>
           ) : (
             <Input />
           )}
         </Form.Item>
       </Col>
     ))}
   </Row>

  { Object.keys(this.state.filterObj).length !== 0 && <Row gutter = {24}>
  <Col>
  <Button ghost
                   type="danger"
                   size="large"
                   onClick={() => { this.setState({ filterObj: {}});
                     form.resetFields();
                     }}
                 >
                   Filter zurücksetzen
                 </Button>

  </Col></Row> }
 </Form>

 </div></div>
  <CitySection
      key={1}
      headline={'Versorgungspartner im Fokus'}
      description={
          <div className="top-veranstaltungen-carousel-wrap">
            <VeranstaltungenSlider superResource={"Versorgungspartner"} veranstaltungenData={filteredVeranstaltungenData.filter( v => v.type === "Versorgungspartner" && v.Fokusiere === true )} />
          </div>
      }
    />

  <CitySection
      key={2}
      headline={'Versorgungspartner'}
      description={
      <SelectableTable
          locale="de"
          keys="name"
          history={this.props.history}
          columns={columnsExtended}
          initialData={filteredVeranstaltungenData}
          initialPageLength={5}
          initialSortBy={{ prop: 'date', order: 'descending' }}
          pageLengthOptions={[ 5, 20, 50 ]}
        />
     }
    />


      </>
    );
  }
}
//        <Media />
//        <Collections collectionsData={collectionsData} />
const CitySection = ({ headline, description, cardsData }) => (
  <div className="city-section-wrapper">
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      {headline}
    </h2>
    <hr
      style={{
        backgroundColor: '#eb008c',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
      {description}
    </p>
    <div className="travel-card-wrapper">

    </div>
  </div>
);

/*
{cardsData &&
        cardsData.map(
          ({
            id,
            city,
            url,
            description,
            currency,
            currentPrice,
            ratings,
            stars,
            discount,
            cashback,
            lastPrice,
            about,
            showMore,
            highlight
          }) => (
            <ExperienceCard
              key={id}
              city={city}
              about={about}
              url={url}
              description={description}
              currency={currency}
              price={currentPrice}
              ratings={ratings}
              stars={stars}
              discount={discount}
              cashback={cashback}
              lastPrice={lastPrice}
              showMore={showMore}
              highlight={highlight}
            />
          )
        )}
*/
// New York Caraousel Images

const backgroundImagesData = [
  {
    id: 1,
    url:
      'https://cdn-imgix-open.headout.com/flaps/city-specific/new-york/desktop/Broadway-week-desktop-4.png?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 2,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/new-york.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 3,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/cashback-01.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 4,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/ny-508-01.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 5,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/ny-attractions-01.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 6,
    url:
      'https://cdn-imgix-open.headout.com/flaps/non-city-specific/desktop/experience-desktop.png?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  }
];


export const withUseFormHook = (Component) => {
    return props => {
        const [form] = Form.useForm();
        return <Component {...props} form={form} />
    }
}

export default withRouter(withUseFormHook(Veranstaltungen));