import React, { useEffect, useState, useContext } from "react";
import { Modal, Button, message, Card, Col, Form, Input, Select, Typography, Row, Spin, Switch, Divider, Upload } from "antd";
import { PlusOutlined, MinusCircleOutlined , FrownOutlined, SafetyCertificateOutlined, MehOutlined, SmileOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { PicturesWall } from './PicturesWall';
import PdfViewer from './PdfViewer';
import { AuthContext } from "../context/AuthContext";
import localObject from '../germanLocale.json';
import { API, API_BASE, STRAPI_API } from "../constant";
import { getToken } from "../helpers";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useAuthContext } from "../context/AuthContext";

const { Text, Link } = Typography;
const { Option } = Select;

const ContactForm = ({ to, type, id, veranstaltungenData }) => {
  const [isLoading, setIsLoading] = useState(false);
    const { setUser, user, availableSubs, sub } = useAuthContext();
  const selectedSubTitle = (sub ? ((availableSubs || []).find((availableSub) => availableSub.stripePlanId === ((sub || {}).plan || {}).id) || {}).title : false || "");

  const handleVeranstaltungUpdate = async (data) => {
    try {

    if (!user.email) {
        return message.error("Bitte hinterlegen Sie eine Emailadresse, um eine Kontaktanfrage zu senden");
    }
        let response;
        const dataToPost = Object.fromEntries(
                               Object.entries(data).filter(([key, value]) => value !== undefined)
                             );

        dataToPost.from = user.email;
        dataToPost.to = to;
        dataToPost.name = user.Name;

        response = await fetch(`${API}/sendcontact`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(dataToPost),
          });

          const responseData = await response.json();

          // Update logic based on your application's requirements
          message.success("Kontaktanfrage erfolgreich gesendet");
        } catch (error) {
          console.error(error);
          message.error("Es ist etwas schief gelaufen...");
        }
  };


  return (
    <Row gutter={[16, 16]}>
      <Col span={24}><br />
        <Form
          name="basic"
          onFinish={handleVeranstaltungUpdate}
          layout="vertical"
        >
          <Form.Item
            name="message_body"
          >
            <Input placeholder="Beschreiben Sie kurz den Grund der Kontaktaufnahme..." />
          </Form.Item>
          <Button
            className="produkt_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {isLoading ? (
              <>
                <Spin size="small" />Email wird gesendet...
              </>
            ) : (
              "Email abschicken"
            )}
          </Button>
    <Text type="secondary" style={{paddingLeft: '1rem'}}>Mit dieser Email teilen Sie Ihren Nutzernamen und Emailadresse mit dem Addressat.</Text>
        </Form>
      </Col>
    </Row>
  );
};

export default ContactForm;
