import React, { Component, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  Col,Flex,
  Form,
  Input,
  message,
  Row,List,
  Spin,Popconfirm,
  Typography,
} from "antd";
import { InboxOutlined, UploadOutlined, CheckSquareOutlined } from '@ant-design/icons';

import { API, STRAPI_API, BEARER } from "../constant";
import { AuthContext, useAuthContext } from "../context/AuthContext";
import { getToken } from "../helpers";

import './Styles/main-home.css';

     const PlansConsumer = (props) => {
        const { availableSubs, user, sub, setSub } = useContext(AuthContext);
        const planStatus = sub ? (sub.status === "active" && !sub.cancel_at_period_end ? "AKTIV" : "STORNIERT") : false;

         return (
         <div style={{ textAlign: 'left'}}>
             <Row gutter={[18, 18]}>
                 {availableSubs && availableSubs.filter(as => as.title.indexOf((user || {}).Rolle) > -1).map((availableSub, i) => {
                        const rowData = availableSub.description.substr(2).split("\n* ");
                        return <Col md={7} lg={7} sm={24} xs={24}>
                        <Card
                        onClick={() => props.handleProductClick({ id: availableSub.id, email: (user || {}).email, url:"http://localhost:1337" })}
                        className={"planCard" + (" planCard" + (i+1))}
                        title={availableSub.title}
                        extra={<span><Typography.Title type="secondary" style={{color: '#eb008c'}} level={4}>{availableSub.price} €</Typography.Title> <Typography.Text type="secondary">/ Jahr</Typography.Text></span>}
                        bordered={false}>
                                <List
                                      bordered
                                      dataSource={rowData}
                                      renderItem={item => (
                                        <List.Item>
                                          <Typography.Text type="success"><CheckSquareOutlined  style={{color: '#eb008c'}} /> </Typography.Text>  &nbsp;&nbsp;{item}
                                        </List.Item>
                                      )}
                                    />
                              </Card>

                      </Col>
                 })}
              </Row>
        </div>)
     }


class YourComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        loading: false,
        error: "",
        user: {},
        isLoading: false,
        availableSubscriptions: false,
        };
    }

  componentDidMount() {
    if (!this.props.user) {
      this.props.history.push("/anmelden");
    }
  }


  render() {
  const { availableSubscriptions } = this.state;
    return (
      <>
      <div className="city-section-wrapper static-padding"><br /><br /><br /><br />
          <h2
            style={{
              textAlign: 'left', marginBottom: 8
            }}
          >
            Aktionen
          </h2>

          <hr
            style={{
              backgroundColor: '#eb008c',
              width: '75px',
              height: '2px',
              border: 'none',
              marginTop: '0px',
              marginLeft: '0px',
              marginBottom: '24px'
            }}
          />
            <Flex gap="small" className="">
              <Button type="primary" size="large" ghost>
                Anerkennungsantrag oder Profilinformation aktualisieren
              </Button>
              <Button disabled={true} type="primary" size="large" ghost>
                Dozenten verwalten
              </Button>
              <Button disabled={true} type="primary" size="large" ghost>
                Seminar beantragen
              </Button>
            </Flex>
          <p
            style={{
              color: '#545454',
              fontSize: '15.3px',
              marginTop: '0px',
              textAlign: 'left',
              lineHeight: '20px'
            }}
          >
          </p>
          <div className="travel-card-wrapper">

          </div>
        </div>

            </>
          );
  }
}

export default withRouter(YourComponent);
