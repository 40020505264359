import React, { useState, useEffect } from 'react';
import { Row, Col,Radio, Typography, Divider , List, Button, Input, Form, Select, Alert, Card, message } from 'antd';
import DraggableModal from './DraggableModal';
import { EditOutlined, EyeOutlined, FormOutlined, DeleteOutlined, PlusOutlined, CommentOutlined } from '@ant-design/icons';
import moment from 'moment';
import { API } from "../constant";
import { getToken } from "../helpers";
import { useHistory } from 'react-router-dom';
import { HomeOutlined, PlusCircleOutlined, CheckCircleOutlined, CalendarOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Meta } = Card;

const StatusEnum = {
  NOT_SUBMITTED: 'Noch nicht eingereicht',
  SUBMITTED_IN_PROGRESS: 'Eingereicht - in Bearbeitung',
  SUBMITTED_REJECTED: 'Eingereicht - abgelehnt',
  SUBMITTED_ACCEPTED: 'Eingereicht - angenommen',
};

function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });
}
const { Title, Paragraph, Link } = Typography;
const KurseList = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isKommentarModalVisible, setIsKommentarModalVisible] = useState(false);
  const [isPMPModalVisible, setIsPMPModalVisible] = useState(false);
  const [isSeminartypModalVisible, setIsSeminartypModalVisible] = useState(false);
  const [currentKurs, setCurrentKurs] = useState(null);
  const [form] = Form.useForm();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
    const [showAll, setShowAll] = useState(false)
    const [currSearch, setCurrSearch] = useState("");
  const [currFilter, setCurrFilter] = useState("");


  const Kurse = props.veranstaltungenData;
  const showEditModal = (kurs) => {
    setCurrentKurs(kurs);
    setIsModalVisible(true);
    form.setFieldsValue(kurs);
  };

  const handlePublishCourse = async () => {
    try {
      // Step 1: Authenticate and get JWT using fetch
      const authResponse = await fetch('https://backend.pmp-online.net/api/auth/local', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          identifier: email,
          password: password,
        }),
      });

      if (!authResponse.ok) {
        throw new Error('Authentication failed');
      }

      const authData = await authResponse.json();
      const jwt = authData.jwt;
      const userId = authData.user.id;
      console.debug("kurs",currentKurs);
const anbieter = (props.anbieterData || {})[0] || {};
      // Step 2: Use the JWT to create an event using fetch
      const eventPayload = {
        data: {
        createdby: userId,
            Anbieternummer: anbieter.anbieternummer,
            Ansprechpartner: currentKurs.kursinformation["Vorname/Nachname Antragsteller"],
            Stadt: currentKurs.kursinformation["Veranstaltungsort2"],
            PLZ: currentKurs.kursinformation["PLZ"],

            Themengebiete: ((currentKurs.kursinformation || []).Seminare || []).map((seminar) => seminar.Seminarthema),

            ExterneReferenten: ((currentKurs.kursinformation || []).Seminare || []).map((seminar) => {

                    const dId = seminar.Dozent;
                    const dO = ((props.dozentData || []).find((dozent) => dozent.id === dId) || {});

                    let dName = dO.Vorname + " " + dO.Nachname;

                    if (dO.Anrede) {
                        dName = dO.Anrede + " " + dName;
                    }

                    if (dO.Titel) {
                        dName = dO.Titel + " " + dName;
                    }
                    return {
                        id: Math.floor(Math.random() * 10000000),
                        Name: dName,
                    }
            }),

            Fortbildungspunkte: [
             {
             "id": Math.floor(Math.random() * 10000000),
             "Punkte": currentKurs.Fortbildungspunkte,
             "Vergabestelle": "Initiative Chronische Wunde e.V. (ICW)"
             }],

          Veranstaltungsname: currentKurs.kursinformation["Titel der Veranstaltung (Wundbezogen)"],
          Veranstaltername: anbieter.Institut,
          Startzeit: (currentKurs.kursinformation.Veranstaltungsdatum || {})[0],
          Endzeit: (currentKurs.kursinformation.Veranstaltungsdatum || {})[1],

        },
      };

      const eventResponse = await fetch('https://backend.pmp-online.net/api/veranstaltungs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify(eventPayload),
      });

      if (!eventResponse.ok) {
      console.error("eventResponse",eventResponse);
        throw new Error('Bitte probieren Sie es später nochmal');
        return;
      }

      message.success('Seminardaten erfolgreich auf PMP-Online kopiert!');
    } catch (error) {
      console.error('Error:', error);
      message.error('Es ging etwas schief: ' + error);
    }
  };
  const showKommentarModal = (kurs) => {
    setCurrentKurs(kurs);
    setIsKommentarModalVisible(true);
  };

  const handleEdit = (dozentid) => {
   props.history.push(`/admin/aktionen/Seminare/${dozentid}`)
  };

  const handleDelete = (id) => {

 fetch(`${API}/kurss/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
          .then(response => response.json())
          .then(data => { console.log(data);
              if (data.error) {
//                              message("", "Es ging etwas schief");
              } else {
//                              message("", "Kurs gelöscht. .");
                  window.location.reload();
              }
          })
          .catch((error) => { console.error('Error:', error);
//                       message("", "Es ging etwas schief");
           });
  };

  const handleAdd = () => {
//    const newKurs = { id: Date.now(), name: '', status: StatusEnum.NOT_SUBMITTED, kommentar: '', updatedAt: moment().toISOString() };
//    setKurse([...Kurse, newKurs]);
//    showEditModal(newKurs);
  };
  const handleSearchChange = (e) => {
    setCurrSearch(e.target.value);
  };
  const handleModeChange = (e) => {
    setCurrFilter(e.target.value);
  };

  const isAuditor = (props.user || {}).Rolle === "Auditor";

  const handleCardClick = (seminarType) => {
    history.push(`/admin/aktionen/Seminar`, { seminartyp: seminarType });
  };

  return (
    <div>
     { !isAuditor && <div style={{textAlign: 'left' }}>
      <Button type="primary" icon={<PlusOutlined />}
      onClick={() => setIsSeminartypModalVisible(true) }
      style={{textAlign: 'left', marginBottom: 16 }}>
        Neues Seminar beantragen
      </Button></div> }

      <div style={{textAlign: 'left' }}>
             <Row gutter={[16, 16]}>
                               <Col md={8} lg={8} sm={24} xs={24}>
                 <Input  onChange={handleSearchChange} style={{minWidth: 260 }} placeholder="Filter Anträge..." />
                            </Col><Col md={12} lg={12} sm={24} xs={24}>
                            <Radio.Group
                              onChange={handleModeChange}
                              value={currFilter}
                              style={{
                                marginBottom: 8,
                              }}
                            >
                              <Radio.Button value="">Alle Anträge anzeigen</Radio.Button>
                              <Radio.Button value={StatusEnum.SUBMITTED_IN_PROGRESS}>Offene</Radio.Button>
                              <Radio.Button value={StatusEnum.SUBMITTED_REJECTED}>Abgelehnt</Radio.Button>
                              <Radio.Button value={StatusEnum.SUBMITTED_ACCEPTED}>Akzeptiert</Radio.Button>
                            </Radio.Group>
                            </Col>
                            </Row>
                            </div>

      <List
        dataSource={Kurse.filter(kurs => currFilter === "" || kurs.status === currFilter)
                               .filter(kurs => {
                                   const anbieterDataString = JSON.stringify((((props.anbieterData || []).find(r => r.createdby === kurs.createdby) || {}) || {})) + moment((((kurs || {}).kursinformation || {})["Veranstaltungsdatum"] || {})[0] || "" ).format('DD.MM.YY HH:mm');
                                   return currSearch === "" || anbieterDataString.toLowerCase().includes((currSearch || "").toLowerCase()) || JSON.stringify(kurs || {}).toLowerCase().includes((currSearch || "").toLowerCase())
                               }).sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))
                           }
        renderItem={(kurs) => {
         const { kursinformation: { PLZ, Veranstaltungsort2 } = {} } = kurs || {};
         const auditBogen = (props.auditData.find((audit) => audit.Kursnummer === kurs.Kursnummer) || {});


         return (
          <Card
            bordered={false}
            style={{ marginBottom: 16 }}
          >
            <List.Item
              actions={
              !isAuditor ? [
                <Button style={{ paddingBottom: 4, background: '#355fb3'}} type="primary" size="large"
                  onClick={() => { setCurrentKurs(kurs); setIsPMPModalVisible(true)}}
                 ><img className="ant-b2tn-icon"  src="/pmp.png" style={{ float: 'left', width: 'auto', height: '22px' }} /><span style={{ paddingLeft: '8px', lineHeight: '22px'}}>Auf PMP-Online veröffentlichen</span></Button>,
                <Button icon={<EditOutlined />}
                  onClick={() => props.history.push(`/admin/aktionen/Seminar/${kurs.id}`) }
                 >Bearbeiten</Button>,
                <Button icon={<DeleteOutlined />} danger onClick={() => handleDelete(kurs.id)}>Löschen</Button>,
              ] : [
                <Button icon={<EyeOutlined />}
                onClick={() =>  props.history.push(`/admin/aktionen/Seminar/${kurs.id}`) }
               >Anzeigen</Button>,
                <Button icon={<FormOutlined />}
                onClick={() => {
                    const auditId = auditBogen.id;
                    props.history.push(`/admin/aktionen/Auditbericht/${auditId}`, { kursnummer: kurs.Kursnummer, auditTyp: isAuditor ? (((props.user || {}).auditinfo || {})[kurs.Kursnummer] || {})["auditTyp"] : "", auditArt: isAuditor ? (((props.user || {}).auditinfo || {})[kurs.Kursnummer] || {})["auditArt"] : "" })
                }  }
               >{ typeof (props.auditData.find((audit) => audit.Kursnummer === kurs.Kursnummer) || {}).id === "undefined" ? "Bericht verfassen..." : "Bericht aktualisieren..."}</Button>
              ]}
            >
              <List.Item.Meta title={((kurs || {}).kursinformation || {})["Titel der Veranstaltung (Wundbezogen)"] + ((kurs || {}).Kursnummer ? (" (" + (kurs || {}).Kursnummer + ")") : "")} />

               { ((kurs || {}).kursinformation || {}).Veranstaltungsdatum && <List.Item.Meta title={<span> { (typeof kurs.kursinformation.Veranstaltungsdatum === 'string') ? kurs.kursinformation.Veranstaltungsdatum : (formatTimestamp(kurs.kursinformation.Veranstaltungsdatum[0]) + " - " + formatTimestamp(kurs.kursinformation.Veranstaltungsdatum[1]))}</span>} /> }
               { (PLZ || Veranstaltungsort2)  && <List.Item.Meta title={<span>{PLZ} {Veranstaltungsort2}</span>} /> }

               { !isAuditor && <List.Item.Meta title={kurs.updatedAt && <span>Letztes Update: {moment(kurs.updatedAt).format('DD.MM.YY HH:mm')}</span>} /> }
               { !isAuditor && <List.Item.Meta style={{ paddingLeft: 4, paddingRight: 12 }}  title={<Alert
                                             message={<span>{kurs.status} {(kurs.status === StatusEnum.SUBMITTED_REJECTED && (
                                                    <Button icon={<CommentOutlined />} onClick={() => showKommentarModal(kurs)}>Kommentar</Button>
                                                  ))}

                                                   {(kurs.Fortbildungspunkte && kurs.status === StatusEnum.SUBMITTED_ACCEPTED && (<p>
                                                                    <span>Fortbildungspunkte:</span> <span>{kurs.Fortbildungspunkte}</span></p>
                                                                  ))}

                                                   {(kurs.Kursgebuehr && kurs.status === StatusEnum.SUBMITTED_ACCEPTED && (<p>
                                                                    <span>Kursgebühr:</span> <span>{kurs.Kursgebuehr}</span></p>
                                                                  ))}
                                                                  </span>
                                                                  }
                                             type={
                                               kurs.status === StatusEnum.NOT_SUBMITTED
                                                 ? 'info'
                                                 : kurs.status === StatusEnum.SUBMITTED_IN_PROGRESS
                                                 ? 'warning'
                                                 : kurs.status === StatusEnum.SUBMITTED_REJECTED
                                                 ? 'error'
                                                 : 'success'
                                             }
                                 /> } /> }
               { isAuditor && <List.Item.Meta style={{ paddingLeft: 4, paddingRight: 12 }}  title={<Alert
                              message={<span>{auditBogen.status} {(auditBogen.status === StatusEnum.SUBMITTED_REJECTED && (
                                     <Button icon={<CommentOutlined />} onClick={() => showKommentarModal(auditBogen)}>Kommentar</Button>
                                   ))}

                                    {(auditBogen.Fortbildungspunkte && auditBogen.status === StatusEnum.SUBMITTED_ACCEPTED && (<p>
                                     <span>Fortbildungspunkte:</span> <span>{auditBogen.Fortbildungspunkte}</span></p>
                                   ))}
                                   </span>}
                              type={
                                auditBogen.status === StatusEnum.NOT_SUBMITTED
                                  ? 'info'
                                  : auditBogen.status === StatusEnum.SUBMITTED_IN_PROGRESS
                                  ? 'warning'
                                  : auditBogen.status === StatusEnum.SUBMITTED_REJECTED
                                  ? 'error'
                                  : 'success'
                              }
                  /> } /> }
            </List.Item>
          </Card>
        )}}
      />
      <DraggableModal
        title="Kurs bearbeiten"
        visible={isModalVisible}
        onOk={handleEdit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Bitte geben Sie einen Namen ein' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Bitte wählen Sie einen Status aus' }]}
          >
            <Select>
              <Option value={StatusEnum.NOT_SUBMITTED}>Anerkennung nicht gestartet</Option>
              <Option value={StatusEnum.SUBMITTED_IN_PROGRESS}>Anerkennung in Bearbeitung</Option>
              <Option value={StatusEnum.SUBMITTED_REJECTED}>Anerkennung abgelehnt</Option>
              <Option value={StatusEnum.SUBMITTED_ACCEPTED}>Anerkannt</Option>
            </Select>
          </Form.Item>
          {form.getFieldValue('status') === StatusEnum.SUBMITTED_REJECTED && (
            <Form.Item
              name="kommentar"
              label="Kommentar"
              rules={[{ required: true, message: 'Bitte geben Sie einen Kommentar ein' }]}
            >
              <Input.TextArea />
            </Form.Item>
          )}
        </Form>
      </DraggableModal>
      <DraggableModal
        title="Auf PMP-Online veröffentlichen"
        visible={isPMPModalVisible}
        onCancel={() => setIsPMPModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsPMPModalVisible(false)}>
            Schließen
          </Button>,
        ]}
      >
        <div>
              <Paragraph>
                Um Ihren Kurs auf PMP Online veröffentlichen zu können, müssen Sie mit einem Account auf{' '}
                <Link href="https://pmp-online.net" target="_blank">
                  pmp-online.net
                </Link>{' '}
                angemeldet und ein entsprechendes Abonnement aktiviert haben.
              </Paragraph>

              <Divider />

              <Form.Item
                label="Email auf PMP-Online"
              ><Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginBottom: '10px' }}
              /></Form.Item>

              <Form.Item
                label="Passwort auf PMP-online"
              ><Input.Password
                placeholder="Passwort auf PMP-Online"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginBottom: '20px' }}
              /></Form.Item>

              <Button type="primary" onClick={handlePublishCourse}>
                Seminar übertragen
              </Button>
              <Divider />
              Nachdem Sie den Kurs auf PMP-Online übertragen haben, können Sie ihn dort bearbeiten, verwalten und veröffentlichen.
              </div>
      </DraggableModal>
      <DraggableModal
        title="Kommentar"
        visible={isKommentarModalVisible}
        onCancel={() => setIsKommentarModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsKommentarModalVisible(false)}>
            Schließen
          </Button>,
        ]}
      >
        <div dangerouslySetInnerHTML={{ __html: currentKurs ? currentKurs.kommentar : '' }} />
      </DraggableModal>
      <DraggableModal
        title="Welchen Seminartyp möchten Sie beantragen?"
        visible={isSeminartypModalVisible}
        onCancel={() => setIsSeminartypModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsSeminartypModalVisible(false)}>
            Schließen
          </Button>,
        ]}
      >
        <div className="card-container">
                <Card
                  hoverable
                  onClick={() => handleCardClick("Rezertifizierungsseminar")}
                >
                  <Meta title="Rezertifizierungsseminar" />
                </Card>

                <Divider>Oder andere Wundseminare... (Freischaltung Ende 2024)</Divider>
    <div className="disabled" style={{ pointerEvents: 'none', opacity: 0.5, background: 'grey' }}>
                <Card
                className="disabled"
                disabled={true}
                  hoverable
                  onClick={() => handleCardClick("Wundexperte ICW®")}
                >
                  <Meta title="Wundexperte ICW®" />
                </Card>

                <Card
                className="disabled"
                disabled={true}
                  hoverable
                  onClick={() => handleCardClick("Ärztlicher Wundexperte ICW®")}
                >
                  <Meta title="Ärztlicher Wundexperte ICW®" />
                </Card>

                <Card
                className="disabled"
                disabled={true}
                  hoverable
                  onClick={() => handleCardClick("Fachtherapeut Wunde ICW®")}
                >
                  <Meta title="Fachtherapeut Wunde ICW®" />
                </Card>

                <Card
                className="disabled"
                disabled={true}
                  hoverable
                  onClick={() => handleCardClick("Fachtherapeut Wunde ICW® Modul 1 (HKP)")}
                >
                  <Meta title="Fachtherapeut Wunde ICW® Modul 1 (HKP)" />
                </Card>

                <Card
                className="disabled"
                disabled={true}
                  hoverable
                  onClick={() => handleCardClick("Pflegetherapeut Wunde ICW®")}
                >
                  <Meta title="Pflegetherapeut Wunde ICW®" />
                </Card>
    </div>
              </div>
      </DraggableModal>
    </div>
  );
};

export default KurseList;
