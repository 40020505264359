import React, { useState, useRef } from "react";
import { Modal } from "antd";
import { HolderOutlined, ExpandAltOutlined } from "@ant-design/icons";
import Draggable from "react-draggable";

const DraggableModal = ({ title, modalRender, ...props }) => {
  const [disabled, setDisabled] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = useRef(null);
  const resizeStartPos = useRef({ x: 0, y: 0 });
    let [dragInfo, setDragInfo] = useState(null)

    let handleDragStart = (e, data) => {
      setDragInfo({
        x: data.x,
        y: data.y,
        time: Date.now()
      })
    }

    let handleDragStop = (e, data) => {
      if (!dragInfo) return
      let change = {
        x: Math.abs(data.x - dragInfo.x),
        y: Math.abs(data.y - dragInfo.y),
        time: Date.now() - dragInfo.time
      }
      if (change.x + change.y <= 10 && change.time < 300 && e.srcElement) {
        e.srcElement.click();
      }
    }

  const handleResizeStart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsResizing(true);
    resizeStartPos.current = {
      x: e.clientX,
      y: e.clientY
    };
  };

  const handleResize = (e) => {
    if (!isResizing || !draggleRef.current) return;

    const dx = e.clientX - resizeStartPos.current.x;
    const dy = e.clientY - resizeStartPos.current.y;

    draggleRef.current.style.width = `${draggleRef.current.offsetWidth + dx}px`;

    // Find and update the modal content
    const modalContent = draggleRef.current.querySelector('.ant-modal-content');
    if (modalContent) {
      modalContent.style.height = `${modalContent.offsetHeight + dy}px`;

      // Also update the modal body to fill available space
      const modalBody = modalContent.querySelector('.ant-modal-body');
      if (modalBody) {
        const modalHeader = modalContent.querySelector('.ant-modal-header');
        const modalFooter = modalContent.querySelector('.ant-modal-footer');
        const headerHeight = modalHeader ? modalHeader.offsetHeight : 0;
        const footerHeight = modalFooter ? modalFooter.offsetHeight : 0;

        modalBody.style.height = `${modalContent.offsetHeight - headerHeight - footerHeight}px`;
        modalBody.style.overflowY = 'auto';
      }
    }

    resizeStartPos.current = {
      x: e.clientX,
      y: e.clientY
    };
  };

  const handleResizeEnd = () => {
    setIsResizing(false);
  };

  React.useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleResize);
      window.addEventListener('mouseup', handleResizeEnd);
      return () => {
        window.removeEventListener('mousemove', handleResize);
        window.removeEventListener('mouseup', handleResizeEnd);
      };
    }
  }, [isResizing]);

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current && draggleRef.current.getBoundingClientRect();
    if (!targetRect) return;

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <Modal
      {...props}
      title={
        <div
          style={{
            width: "100%",
            cursor: "move",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
          onMouseEnter={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseLeave={() => {
            setDisabled(true);
          }}
        >
          <HolderOutlined style={{ fontSize: "16px", color: "#888" }} />
          {title}
        </div>
      }
      modalRender={(modal) => (
        <Draggable onStart={handleDragStart} onStop={handleDragStop}
          disabled={disabled || isResizing}
          bounds={bounds}
          onStart={onStart}
        >
          <div ref={draggleRef}>
            {modal}
            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                pointerEvents: "auto",
                width: "20px",
                height: "20px",
                cursor: "se-resize",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
              onMouseDown={handleResizeStart}
            >
              <ExpandAltOutlined
                style={{
                  fontSize: "14px",
                  color: "#888",
                  transform: "rotate(90deg)"
                }}
              />
            </div>
          </div>
        </Draggable>
      )}
    />
  );
};

export default DraggableModal;