import React from 'react';
import './Styles/footer.css';
import HeadoutLogo from './Images/ICW_Logo_mobile.png';
import Googlelogo from './Images/gplus-connect.png';
import Twitterlogo from './Images/twitter-connect.png';
import Facebooklogo from './Images/fb-connect.png';
import Pinterestlogo from './Images/pinterest-connect.png';
import Instagramlogo from './Images/instagram-connect.png';

import { Link } from 'react-router-dom';
const Footer = () => (
  <div className="footer-wrap">
    <div className="footer-wrapper">
      <div className="column-one">
        <img id="footer" src={HeadoutLogo} style={{width: "28px"}} alt="Headout" />
        <p>ICW Portal: Anerkennungs- und Zertifizierungsstelle</p>

        <p>&copy; ICW Portal</p>
      </div>
      <div className="column-three">
        <ul>
          <li><Link to={{ pathname: `/info` }} key={'v_sa'} className="link_sa view-all footer-link">Über ICW Portal</Link></li>
          <li><Link to={{ pathname: `/datenschutz` }} key={'v_sa'} className="link_sa view-all footer-link">Datenschutz</Link></li>
          <li><Link to={{ pathname: `/impressum` }} key={'v_sa'} className="link_sa view-all footer-link">Impressum</Link></li>
        </ul>
      </div>
      <div className="column-four">
        <h3 style={{ paddingBottom: '12px' }} >Kontakt</h3>
        <p style={{ paddingLeft: '0px' }} >Kontaktieren Sie uns gerne per E-Mail:</p>
        <p style={{ paddingLeft: '0px', color: '#4fc3f7', cursor: 'pointer' }}>
          geschaeftsstelle(at)icwunden.de
        </p>
      </div>
    </div>
  </div>
);

export default Footer;
